import { Translate, translate } from "react-i18nify";
import { PageHeader } from "../../components/Layout/PageHeader";
import PageContainer from "../../components/PageContainer";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ProductionStep } from "./ProductionStep";
import { UserService, DeviceService, TemplateService } from "../../services/DataService";
import { Roles } from "../../redux/actions/auth";
import { RequireRole } from "../../utils/RequireRole";
import { is } from "date-fns/locale";
export const DEVICE_TYPE = {
  HOT: { label: "HOT 100", code: "Hot100Device" },
  PRO100: { label: "PRO 100", code: "Pro100Device" },
};


export const Production = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { type } = useParams();
  const [doneLoadingTypes, setDoneLoadingTypes] = useState(true);
  const [loadingTypeData, setLoadingTypeData] = useState(false);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [deviceType, setType] = useState(type ? type : "");
  const [types, setTypes] = useState([DEVICE_TYPE.PRO100, DEVICE_TYPE.HOT]);
  const [deviceCount, setDeviceCount] = useState({
    test: 0,
    package: 0,
    productionQa: 0,
    o2maticQaAfterProductionQa: 0,
    ship: 0,
    underInvestigation: 0,
    repairProduction: 0,
    repairO2matic: 0,
    reapirTotal: 0,
    o2maticTest: 0,
    o2maticQaAfterO2maticTest: 0,
  });
  const [userRoleLocations, setUserRoleLocations] = useState([]);
  const [isProdRepairer, setIsProdRepairer] = useState(false);
  const [isO2maticRepairer, setIsO2maticRepairer] = useState(false);

  useEffect(() => {
    (async () => {
        await UserService.getActiveUserRolesLocations()
        .then((data) => {
            setUserRoleLocations(data.data);
        }).catch((error) => {
            console.log(error);
        })
    })();
}, [])

  useEffect(() => {
    let isProdRepairer = false;
    let isO2maticRepairer = false;
    if(userRoleLocations.length !== 0){
        userRoleLocations.forEach(role => {
            if(role.roleName === "Repairer" && role.locationId === 21){
                isO2maticRepairer = true;
            }
            if(role.roleName === "Repairer" && role.locationId === 22){
                isProdRepairer = true;
            }
        })
    }
    setIsO2maticRepairer(isO2maticRepairer);
    setIsProdRepairer(isProdRepairer);
    console.log("isProdRepairer", isProdRepairer);
    console.log("isO2maticRepairer", isO2maticRepairer);
  },[userRoleLocations])

  useEffect(() => {
    (async () => {
      setLoadingTypeData(true);

      let getTemplatesResult,
        template,
        assembled,
        tested,
        packaged,
        productionQaApproved,
        releasedForSale, // previously O2matic QA approved
        readyForRepair,
        readyForInvestigation,
        atRepairO2matic,
        readyForTesting,
        readyForO2maticApproval;
        
      try {
        getTemplatesResult = await TemplateService.getAll();
        template = getTemplatesResult.data.find((i) => i.code == deviceType);
        console.log(template)
      } catch (e) {
        console.error("An error occurred while fetching templates:", e);
      }

      try {
        assembled = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "Assembled",
          [Roles.Tester]
        );
      } catch (e) {
        console.error("An error occurred while fetching assembled devices:", e);
      }

      try {
        tested = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "Tested",
          [Roles.Packager]
        );
      } catch (e) {
        console.error("An error occurred while fetching tested devices:", e);
      }

      try {
        packaged = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "Packaged",
          [Roles.ProductionQa]
        );
      } catch (e) {
        console.error("An error occurred while fetching packaged devices:", e);
      }

      try {
        productionQaApproved = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "Prod QA Approved",
          [Roles.O2maticQa]
        );
      } catch (e) {
        console.error(
          "An error occurred while fetching production QA approved devices:",
          e
        );
      }

      try {
        releasedForSale = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "ReleasedForSale",
          [Roles.Shipper]
        );
      } catch (e) {
        console.error(
          "An error occurred while fetching ReleasedForSale devices released for sale:",
          e
        );
      }

      try {
        readyForInvestigation = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "ReadyForInvestigation",
          [Roles.Repairer]
        );
      } catch (e) {
        console.error(
          "An error occurred while fetching devices under investigation:",
          e
        );
      }

      try {
        readyForRepair = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "ReadyForRepair",
          [Roles.Repairer]
        );
      } catch (e) {
        console.error(
          "An error occurred while fetching devices ready for repair:",
          e
        );
      }

      try {
        atRepairO2matic = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "AtRepairO2matic",
          [Roles.Repairer]
        );
      } catch (e) {
        console.error(
          "An error occurred while fetching devices ready for repair:",
          e
        );
      }
    
      try {
        readyForTesting = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "ReadyForTesting",
          [Roles.O2maticTester]
        );
      } catch (e) {
        console.error(
          "An error occurred while fetching devices ready for testing:",
          e
        );
      }

      try {
        readyForO2maticApproval = await DeviceService.getByTemplate(
          template.id,
          0,
          500,
          "ReadyForO2maticApproval",
          [Roles.O2maticQa]
        );
      } catch (e) {
        console.error(
          "An error occurred while fetching devices ready for approval from O2matic QA:",
          e
        );
      }
      

      setDeviceCount({
        test: assembled ? assembled.data.length : 0,
        package: tested ? tested.data.length : 0,
        productionQa: packaged ? packaged.data.length : 0,
        o2maticQaAfterProductionQa: productionQaApproved ? productionQaApproved.data.length : 0,
        ship: releasedForSale ? releasedForSale.data.length : 0,
        underInvestigation: readyForInvestigation ? readyForInvestigation.data.length : 0,
        repairProduction: readyForRepair ? readyForRepair.data.length : 0,
        repairO2matic: atRepairO2matic ? atRepairO2matic.data.length : 0,
        o2maticTest: readyForTesting ? readyForTesting.data.length : 0,
        o2maticQaAfterO2maticTest: readyForO2maticApproval ? readyForO2maticApproval.data.length : 0,
      });

      setLoadingTypeData(false);
    })();
  }, [deviceType, rolesLoading]);


  const onTypeChange = (event) => {
    const newType = event.target.value;
    navigate(`/production/${newType}`);
    setType(newType);
  };

  const onAssembleClicked = () => {
    switch (deviceType) {
      case DEVICE_TYPE.HOT.code: {
        navigate("/device/HOT/Create");
        break;
      }
      case DEVICE_TYPE.PRO100.code: {
        navigate("/device/PRO/Create");
        break;
      }
    }
  };

  const renderSteps = () => {
    return (
      <>
        
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>

        <RequireRole authorize={[Roles.Assembler]}>
          <Box sx={{ display: "flex", flexDirection: "row" , justifyContent: 'space-between'}}>
          <Divider sx={{height:"50px",mr:2}} orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: "265px",
                minHeight: "75px",
              }}
            >
              <Typography variant="h5"><Translate value="Assemble" /></Typography>
              <Typography variant="body">
              <Translate value="AssembleNewDevice" />
              </Typography>
            </Box>      

            <Button
                onClick={onAssembleClicked}
                sx={{ mt:1, minWidth: "100px" ,height: "40px"}}
                color="primary"
                variant="contained"
              >
                <Translate value="Assemble" />
              </Button>
          </Box> 
        </RequireRole>


          <ProductionStep
            authorize={[Roles.Tester]}
            label={translate("Test")}
            pending={deviceCount.test}
            redirect={`/production/test/${deviceType}`}
          />
          <ProductionStep
            authorize={[Roles.Packager]}
            label={translate("Package")}
            pending={deviceCount.package}
            redirect={`/production/package/${deviceType}`}
          />
          <ProductionStep
            authorize={[Roles.ProductionQa]}
            label={translate("ProductionQA")}
            pending={deviceCount.productionQa}
            redirect={`/production/qa/${deviceType}`}
          />
          <ProductionStep
            authorize={[Roles.O2maticQa]}
            label={translate("O2maticQA")}
            pending={deviceCount.o2maticQaAfterProductionQa + deviceCount.o2maticQaAfterO2maticTest}
            redirect={`/production/o2maticqa/${deviceType}`}
          />
          <ProductionStep
            authorize={[Roles.Shipper]}
            label={translate("Ship")}
            pending={deviceCount.ship}
            redirect={`/production/ship/${deviceType}`}
          />
          <ProductionStep
            authorize={[Roles.Repairer]}
            label={translate("UnderInvestigation")}
            pending={deviceCount.underInvestigation}
            redirect={`/production/underInvestigation/${deviceType}`}
          />
          <ProductionStep
            authorize={[Roles.Repairer]}
            label={translate("Repair")}
            pending={deviceCount.repairProduction + deviceCount.repairO2matic}
            redirect={`/production/repair/${deviceType}`}
          />
          <ProductionStep
            authorize={[Roles.O2maticTester]}
            label={translate("O2matic Test")}
            pending={deviceCount.o2maticTest}
            redirect={`/production/o2maticTest/${deviceType}`}
          />

        </Box>
      </>
    );
  };

  return (
    <PageContainer>
      <PageHeader leftAlign>
        <Translate value="Production" />
      </PageHeader>

      <Box sx={{ mt: 2, width: "300px" }}>
        <InputLabel id="selectTypeLabel">
          <Translate value="SelectType" />
        </InputLabel>
        {doneLoadingTypes ? (
          <Select
            sx={{ minWidth: "200px" }}
            required
            id="selectType"
            displayEmpty
            value={deviceType}
            onChange={onTypeChange}
          >
            <MenuItem value={""}>
              <Translate value="SelectType" />
            </MenuItem>
            {types.map((l) => {
              return (
                <MenuItem key={l.code} value={l.code}>
                  {l.label}
                </MenuItem>
              );
            })}
          </Select>
        ) : null}
        {deviceType != "" && !loadingTypeData ? renderSteps() : null}
      </Box>
      {deviceType != "" && loadingTypeData ? (
        <Translate value="Loading" />
      ) : null}
    </PageContainer>
  );
};
