import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import PageContainer from '../../components/PageContainer';
import { NotificationService } from "../../services/DataService";
import { PageHeader } from '../../components/Layout/PageHeader';
import { Translate, Localize, translate } from 'react-i18nify';
import { Box, Typography, Button, Checkbox, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch, Slider, Alert } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

export default function Notification() {


    const [loading,setLoading]= useState(false)
    const [notification, setNotification] = useState({"isActive":false,"message":""});
    const navigate = useNavigate();

    const regex = /\p{Extended_Pictographic}/ug

    const [wrongText, setWrongText] = useState("");
    const [isWrongText, setIsWrongText] = useState(false);
    const [unableToSubmitText, setUnableToSubmitText] = useState("");
    const [isUnableToSubmit, setIsUnableToSubmit] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                let apiResult = await NotificationService.getNotification();
                setNotification(apiResult.data);
            } catch (error) {
                
            }
            finally{
                setLoading(false)
            }
        })();
    }, [])

    const handleNotificationChange = (event) => {
        console.log({notification})
        console.log(event.target.value)
        if(regex.test(notification.message)){
            console.log("all is NOT ok")
            setIsWrongText(true)
            setWrongText(translate("RegExpEmoji"))
            setNotification(prev =>{
                let newState={"isActive":prev.isActive,"message":event.target.value}
                    return newState
                })
        } else {
            console.log("all is ok")
            setIsWrongText(false)
            setWrongText("")
            setNotification(prev =>{
                let newState={"isActive":prev.isActive,"message":event.target.value}
                    return newState
                })
        }
    };

    const handleActiveChange = event => {
        setNotification(prev=>{
            let newState={"isActive":event.target.checked,"message":prev.message}
            return newState
        })
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(isWrongText){
            setIsUnableToSubmit(true)
            setUnableToSubmitText(translate("Unable to submit"))
        } else {
            setIsUnableToSubmit(false)
            setUnableToSubmitText("")
            await NotificationService.updateNotification(notification.message,notification.isActive)
            .catch(err => {
                console.error({err: err})
            }).finally(() => {
                navigate("/O2maticAdministrator")
                window.location.reload(true)
            })
        }
    }

    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="AddNotification" /></PageHeader>
            <Typography sx={{ mt: 1, mb: 1, width:{xs:"100%", sm:"600px"}}} variant="body">
                <Translate value="AddNotificationText" />
            </Typography>
            <Box component="form" sx= {{display:"flex",flexDirection:"column",width:{xs:"100%", sm:"600px"},overFlow:"visible",alignSelf:"start"}} onSubmit={handleSubmit}>
                <FormControlLabel control={<Checkbox checked={notification.isActive} onChange={handleActiveChange} />} label={translate("NotificationActive")} />
                <InputLabel id="notification"><Translate value="Notification" /></InputLabel> 
                <TextField sx={{ width:{xs:"100%", sm:"600px"}}} rows={5} multiline required id="outlined-basic" placeholder={translate("WriteMessageHere")} onChange={handleNotificationChange} value={notification.message} variant="outlined" />
                {isWrongText ? <Alert severity="error">{wrongText}</Alert> :<></> }
                <Button sx={{ mt: 2, width:{xs:"100%", sm:"200px"}}} color="success" variant="contained" type="submit" startIcon={<SaveIcon />}>
                    <Translate value="Save" />
                </Button>
                {isUnableToSubmit ? <Alert severity="error">{unableToSubmitText}</Alert> :<></> }
            </Box>

        </PageContainer>
    )
}
