import { Translate, translate } from "react-i18nify";
import { PageHeader } from "../../../../components/Layout/PageHeader";
import PageContainer from "../../../../components/PageContainer";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DEVICE_TYPE } from "../../Production";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect, useState } from "react";
import {
  DeviceService,
  MetadataService,
} from "../../../../services/DataService";
import AlertBox from "../../../../components/AlertBox";
import { RepairDialog } from "./RepairDialog";
import { ca } from "date-fns/locale";
import { set } from "date-fns";

export const Repair = (props) => {
  const { udsn, type } = useParams();

  const navigate = useNavigate();
  const [device, setDevice] = useState({});
  const [submitState, setSubmitState] = useState(false);
  const [status, setStatus] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [repairs, setRepairs] = useState([]);
  const [statusList, setStatusList] = useState();
  const [repairDialogData, setRepairDialogData] = useState({ open: false });
  const [alertBox, setAlertBox] = useState({
    visibility: false,
    statusCode: null,
    text: null,
    error: null,
  });

  const handleAlertBox = (visibility, statusCode, text, error) => {
    setAlertBox({
      visibility: visibility,
      statusCode: statusCode,
      text: text,
      error: error,
    });
  };

  const dismissAlertBox = () => {
    setAlertBox({
      visibility: false,
      statusCode: null,
      text: null,
      error: null,
    });
  };

  const handleStatusChange = (e) => {
    const newValue = e.target.value;
    setStatus(newValue);
    setRepairs([]);
  };

  const checkForNullValuesHot = (device) => {
    let nullComponents = [];
    try { // try getting chassis
      const chassis = device?.children.find(
        (children) => children.template.code === "Hot100Chassis"
      );
      if(chassis == null)
        nullComponents.push("Hot100Chassis");
    }catch(error){
      nullComponents.push("Hot100Chassis");
    }

    try { // try getting print
      device?.children
      ?.find((children) => children.template.code === "Hot100Chassis")
      .children?.find((children) => children.template.code === "Hot100Print");
    } catch (error) {
      nullComponents.push("Hot100Print");
    }

    try { // try getting flowmeter
      device?.children
      ?.find((children) => children.template.code === "Hot100Chassis")
      .children?.find((children) => children.template.code === "Flowmeter");
    } catch (error) {
      nullComponents.push("Flowmeter");
    }

    try { // try getting battery
      device.children
      .find((children) => children.template.code === "Hot100Chassis")
      .children.find?.(children => children.template.code === "Hot100Print")
      .children.find?.(children => children.template.code === "Hot100Battery");
    }catch(error){
      nullComponents.push("Hot100Battery");
    }
    return nullComponents;
  }

  const checkForNullValuesPro = (device) => {
    let nullComponents = [];
    try { // try getting chassis
      const chassis = device?.children.find(
        (children) => children.template.code === "Pro100Chassis"
      );
      if(chassis == null)
        nullComponents.push("Pro100Chassis");
    }catch(error){
      nullComponents.push("Pro100Chassis");
    }

    try { // try getting print
      device?.children
      ?.find((children) => children.template.code === "Pro100Chassis")
      .children?.find((children) => children.template.code === "Pro100Print");
    } catch (error) {
      nullComponents.push("Pro100Print");
    }

    try{ // try getting screen
      device?.children 
      ?.find((children) => children?.template.code === "Pro100Chassis")
      .children?.find((children) => children?.template.code === "Pro100Print")
      .children?.find((children) => children?.template.code === "Pro100Screen");
    }catch(error){
      nullComponents.push("Pro100Screen");
    }

    try { // try getting flowmeter
      device?.children
      ?.find((children) => children.template.code === "Pro100Chassis")
      .children?.find((children) => children.template.code === "Flowmeter");
    } catch (error) {
      nullComponents.push("Flowmeter");
    }

    try { // try getting battery
      device.children
      .find((children) => children.template.code === "Pro100Chassis")
      .children.find?.(children => children.template.code === "Valve");
    }catch(error){
      nullComponents.push("Valve");
    }

    try { // try getting oximeter
      device?.children 
      ?.find((children) => children?.template.code === "Pro100Chassis")
      .children?.find((children) => children?.template.code === "Oximeter");
    } catch (error) {
      console.log("Oximeter error", error);
      nullComponents.push("Oximeter");
    }

    return nullComponents;
  }

  const checkForNullValues = (device) => {
    let  nullComponents = [];
    if(type === DEVICE_TYPE.HOT.code)
      nullComponents = checkForNullValuesHot(device);
    else
      nullComponents = checkForNullValuesPro(device);

    if(nullComponents.length > 0){
      repairs.forEach((repair) => {
        nullComponents = nullComponents.filter((component) => component !== repair.code);
      })
    }  
    return nullComponents;
  }

  const submitRepair = async () => {
    const nullComponents = checkForNullValues(device);
    if(nullComponents.length > 0){
      handleAlertBox(true, 400, "Missing components", nullComponents.join(","));
      return
    }
    try {
      setSubmitState(true);
      let result = await DeviceService.repairDevice(
        device.id,
        type,
        repairs,
        status
      );

      if (result.status >= 200 && result.status < 300) {
        handleAlertBox(true, result.status, "Device changed", null);
      } 

      else {
        handleAlertBox(true, result.status, result.statusText, null);
      }
      setTimeout(() => {
        navigate(`/production/${type}`);
      }, 1000);
    } catch (e) {
      console.log({e})
      if(e.response.status===422){
        handleAlertBox(true, e.response.status, "Duplicate entry:", e.response.data?.join(","));
      }
      else{
        handleAlertBox(true, e.response.status, e.response.statusText, e.response.data?.join(","));
      }
    } finally {
      setSubmitState(false);
    }
  };

  useEffect(() => {
    (async () => {
      let deviceResult = await DeviceService.getDeviceByUdsn(udsn);
      setDevice(deviceResult.data);
      setStatus(deviceResult.data.metaData?.find((x) => x.metadataId == 2)?.id);
      setCurrentStatus(deviceResult.data.metaData?.find((x) => x.metadataId == 2)?.value);
      //setLoadingDevice(true);
    })();

    (async () => {
      let values = await MetadataService.getValues();
      setStatusList(values.data.filter((x) => x.metaData.id == 2));
      //setMetadataLoading(true);
    })();
  }, []);

  const HotComponents = (props) => {
    const nullComponents = []

    // useEffect(() => {
    //   if(nullComponents.length === 0)
    //     setSubmitState(false);
    //   else
    //     setSubmitState(true);
    // }, [nullComponents]);

    const removeNullComponent = (code) => {
      nullComponents = nullComponents.filter((c) => c !== code);
    }

    const setRepairDialog = (component) => {
      setRepairDialogData((prev) => {
        return {
          ...prev,
          component: component,
          open: component.open,
        };
      });
    };

    const device = props.device;
    if (device == null) {
      return;
    }

    const chassis = device?.children?.find(
      (children) => children.template.code === "Hot100Chassis"
    );

    let print = {metaData: [{name: "Serial Number", value: null}]};
    try {
      print = device?.children
      ?.find((children) => children.template.code === "Hot100Chassis")
      .children?.find((children) => children.template.code === "Hot100Print");
    } catch (error) {
      // setNullcomponents((prev) => [...prev, "Hot100Print"]);
      nullComponents.push("Hot100Print");
      console.log("Print not present ");
    }

    let flowmeter = {metaData: [{name: "Serial Number", value: null}]};
    try {
      flowmeter = device?.children
      ?.find((children) => children.template.code === "Hot100Chassis")
      .children?.find((children) => children.template.code === "Flowmeter");
    } catch (error) {
      // setNullcomponents((prev) => [...prev, "Flowmeter"]);
      nullComponents.push("Flowmeter");
      console.log("Flowmeter not present");
    }
  
    let battery = {metaData: [{name: "Serial Number", value: null}]};
    try {
      battery = print?.children.find(
        (children) => children.template.code === "Hot100Battery"
      );
    }catch(error){
      // setNullcomponents((prev) => [...prev, "Hot100Battery"]);
      nullComponents.push("Hot100Battery");
      console.log("Battery not present");
    }

    return (
      <>
        <Component
          name="Hot100Chassis"
          replaced={repairs.find((repair) => repair.code === "Hot100Chassis")}
          emptyComponent
          value={
            chassis?.metaData?.find(
              (metadata) => metadata.name === "Serial Number"
            )?.value
          }
          code="Hot100Chassis"
          onClick={(component) => setRepairDialog(component)}
        />

        <Component
          name="Hot100Print"
          replaced={repairs.find((repair) => repair.code === "Hot100Print")}
          pattern="^AE[0-9]{5,7}$"
          format="AE1234567"
          value={
            print?.metaData?.find(
              (metadata) => metadata.name === "Serial Number"
            )?.value
          }
          code="Hot100Print"
          onClick={(component) => setRepairDialog(component)}
        />

        <Component
          name="Hot100Battery"
          replaced={repairs.find((repair) => repair.code === "Hot100Battery")}
          emptyComponent
          code="Hot100Battery"
          onClick={(component) => setRepairDialog(component)}
        />

        <Component
          name="Flowmeter"
          replaced={repairs.find((repair) => repair.code === "Flowmeter")}
          pattern="^[a-zA-Z0-9]{10}$"
          format="AbCd123456"
          value={
            flowmeter?.metaData?.find(
              (metadata) => metadata.name === "Serial Number"
            )?.value
          }
          code="Flowmeter"
          onClick={(component) => setRepairDialog(component)}
        />
      </>
    );
  };

  const ProComponents = (props) => {
    const setRepairDialog = (component) => {
      setRepairDialogData((prev) => {
        return {
          ...prev,
          component: component,
          open: component.open,
        };
      });
    };
    const device = props.device;
    if (device == null) {
      return;
    }
    const chassis = device?.children?.find(
      (children) => children?.template.code === "Pro100Chassis"
    );

    let flowmeter = {metaData: [{name: "Serial Number", value: null}]};
    try {
      flowmeter = device?.children
      ?.find((children) => children?.template.code === "Pro100Chassis")
      .children?.find((children) => children?.template.code === "Flowmeter");
    } catch (error) {
      console.log("Flowmeter error", error);
    }
    
    let valve = {metaData: [{name: "Serial Number", value: null}]};
    try{
      valve = device?.children
      ?.find((children) => children?.template.code === "Pro100Chassis")
      .children?.find((children) => children?.template.code === "Valve");
    }catch(error){
      console.log("Valve error", error);
    }

    let print = {metaData: [{name: "Serial Number", value: null}]};
    try {
      print = device?.children
      ?.find((children) => children?.template.code === "Pro100Chassis")
      .children?.find((children) => children?.template.code === "Pro100Print");
    } catch (error) {
      console.log("Print error ", error);
    }

    let oximeter = {metaData: [{name: "Serial Number", value: null}]};
    try {
      oximeter = device?.children
      ?.find((children) => children?.template.code === "Pro100Chassis")
      .children?.find((children) => children?.template.code === "Oximeter");
    } catch (error) {
      console.log("Oximeter error", error);
    }
    
    // const processor = device?.children
    //   ?.find((children) => children?.template.code === "Pro100Chassis")
    //   .children?.find((children) => children?.template.code === "Pro100Print")
    //   .children?.find(
    //     (children) => children?.template.code === "Pro100Processor"
    //   );

    // const screen = device?.children
    //   ?.find((children) => children?.template.code === "Pro100Chassis")
    //   .children?.find((children) => children?.template.code === "Pro100Print")
    //   .children?.find((children) => children?.template.code === "Pro100Screen");
    
    

    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
          <Translate value="Name" />: {device?.name}
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
          <Translate value="Components" />
        </Typography>
        <Component
          name="Chassis"
          replaced={repairs.find((repair) => repair.code === "Pro100Chassis")}
          emptyComponent
          code="Pro100Chassis"
          onClick={(component) => setRepairDialog(component)}
        />

        <Component
          name="Flowmeter"
          replaced={repairs.find((repair) => repair.code === "Flowmeter")}
          pattern="^[a-zA-Z0-9]{10}$"
          format="AbCd123456"
          value={
            flowmeter?.metaData?.find(
              (metadata) => metadata.name === "Serial Number"
            )?.value
          }
          code="Flowmeter"
          onClick={(component) => setRepairDialog(component)}
        />
        <Component
          name="Valve"
          pattern="^[0-9]{6}$"
          replaced={repairs.find((repair) => repair.code === "Valve")}
          format="123456"
          value={
            valve?.metaData?.find(
              (metadata) => metadata.name === "Serial Number"
            )?.value
          }
          code="Valve"
          onClick={(component) => setRepairDialog(component)}
        />
        <Component
          name="Print"
          pattern="^AE[0-9]{5,7}$"
          replaced={repairs.find((repair) => repair.code === "Pro100Print")}
          format="AE1234567"
          value={
            print?.metaData?.find(
              (metadata) => metadata.name === "Serial Number"
            )?.value
          }
          code="Pro100Print"
          onClick={(component) => setRepairDialog(component)}
        />
        <Component
          name="Screen"
          replaced={repairs.find((repair) => repair.code === "Pro100Screen")}
          emptyComponent
          code="Pro100Screen"
          onClick={(component) => setRepairDialog(component)}
        />

        <Component
          name="Oximeter"
          pattern="^[0-9]{8,10}$"
          replaced={repairs.find((repair) => repair.code === "Oximeter")}
          format="12345678"
          value={
            oximeter?.metaData?.find(
              (metadata) => metadata.name === "Serial Number"
            )?.value
          }
          code="Oximeter"
          onClick={(component) => setRepairDialog(component)}
        />
      </Box>
    );
  };
  const Component = ({
    name,
    code,
    value,
    pattern,
    format,
    onClick,
    emptyComponent,
    replaced,
  }) => {
    return (
      <Box
        sx={{
          mt: 1,
          display: "flex",
          width: "300px",
          height: "75px",
          flexDirection: "column",
          border: "2px solid",
          borderColor: replaced ? "green" : "primary.main",
          bgcolor: replaced ? "#c0e9c0" : "white",
          p: 1,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
            <Translate value={name} />
          </Typography>
          <Button
            onClick={() =>
              onClick({
                name: name,
                value: value,
                code: code,
                open: true,
                format: format,
                pattern: pattern,
                emptyComponent: emptyComponent ? true : false,
              })
            }
            sx={{ marginLeft: "auto", width: "75px", height: "40px" }}
            color="primary"
            variant="contained"
          >
            <Translate value={"Replace"} />
          </Button>
        </Box>
        <Typography sx={{ mt: 1, mb: 1 }} variant="body">
          {value}
        </Typography>
      </Box>
    );
  };

  return (
    <PageContainer>
      <PageHeader leftAlign>
        <Translate value="Repair" />
      </PageHeader>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h5">
        <Translate value="RepairDeviceWithUdsn" />: {udsn}
      </Typography>
      <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
        <Translate value="SelectStatus" />
      </Typography>
      <Select
        required
        id="status"
        displayEmpty
        value={status}
        onChange={handleStatusChange}
        sx={{ width: 300 }}
      >

        {statusList?.map((value) => {
          if(currentStatus=="ReadyForRepair"){ // if status = ReadyForRepair
            if (
              value?.value === "Assembled" ||
              value?.value === "SCRAPPED" ||
              value?.id === device?.metaData?.find((x) => x.metadataId == 2)?.id
            ) {
              return (
                <MenuItem key={value?.id} value={value?.id}>
                  {value?.value}
                </MenuItem>
              );
            }
          } else if(currentStatus=="AtRepairO2matic"){ // if status = AtRepairO2matic
            if (
              value?.value === "SCRAPPED" ||
              value?.value === "ReadyForInvestigation" ||
              value?.value === "ReadyForTesting" ||
              value?.id === device?.metaData?.find((x) => x.metadataId == 2)?.id
            ) {
              return (
                <MenuItem key={value?.id} value={value?.id}>
                  {value?.value}
                </MenuItem>
              );
            }
          }
        })}

      </Select>
      {}
      {statusList?.find(s=>s.id===status)?.value==="Assembled"?
      DEVICE_TYPE.HOT.code === type ? (
        <HotComponents device={device} />
      ) : (
        <ProComponents device={device} />
      ):null}

      <Button
        sx={{ mt: 2 }}
        type="submit"
        disabled={submitState}
        onClick={submitRepair}
        variant="contained"
      >
        <Translate value="Submit" />{" "}
      </Button>
      <RepairDialog
        component={repairDialogData.component}
        open={repairDialogData.open}
        confirmButtonVariant={"primary"}
        confirmText={translate("Replace")}
        declineText={translate("Cancel")}
        onConfirm={(repair) => {
          setRepairDialogData({ open: false });
          setRepairs(repairs.filter(r => r.code.localeCompare(repair.code) != 0)) //Removes the old repair if it exists.
          setRepairs((prev) => [...prev, repair]);
        }}
        onClose={() => {
          setRepairDialogData({ open: false });
        }}
      />

      <AlertBox
        visible={alertBox.visibility}
        text={alertBox.text}
        error={alertBox.error}
        statusCode={alertBox.statusCode}
        onHide={dismissAlertBox}
      />
    </PageContainer>
  );
};
