import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/Layout/PageHeader'
import { Box } from '@mui/system'
import { Typography, Button, FormControl, TextField, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DataGrid } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { EventLogService } from '../../services/DataService'
import { LogService } from '../../services/DataService'
import { UserService, DeviceService } from '../../services/DataService'
import { MetadataService } from '../../services/DataService'
import { Translate, Localize, translate } from 'react-i18nify';
import { useParams, useNavigate } from 'react-router-dom';
import { LocationService } from '../../services/DataService';
import { Devices } from '@mui/icons-material'
import AlertBox from "../../components/AlertBox"
import { PatientDetailText } from "../../components/Patient/PatientDetailText";

export const HotDeviceEdit = () => {




    const [device, setDevice] = useState([]);
    const { udsn } = useParams();
    const navigate = useNavigate();
    const [loadingLocation, setloadingLocation] = useState(false);
    const [locations, setLocations] = useState([]);
    const [location, setLocation] = useState();
    const [owner, setOwner] = useState();
    const [metadataValues, setMetadataValues] = useState();
    const [metadataLoading, setMetadataLoading] = useState(false);
    const [loadingDevice, setLoadingDevice] = useState(false);
    const [userRoleLocations, setUserRoleLocations] = useState([]);
    const [isO2maticDeviceManager, setIsO2maticDeviceManager] = useState(false);

    const [statusList, setStatusList] = useState();
    const [dmrList, setDmrList] = useState();
    const [swList, setSwList] = useState();
    const [fwList, setFwList] = useState();

    const [fw, setFw] = useState();
    const [mac, setMac] = useState();
    const [puid, setPuid] = useState();
    const [sw, setSw] = useState();
    const [dmr, setDmr] = useState();
    const [status, setStatus] = useState();

    const [remark, setRemark] = useState('');


    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );

    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }

    const dismissAlertBox = () => {
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }

    useEffect(() => {
        (async () => {
            await UserService.getActiveUserRolesLocations()
            .then((data) => {
                setUserRoleLocations(data.data);
            }).catch((error) => {
                console.log(error);
            })
        })();
    }, [])

    // get locations of user with repairer role
    useEffect(() => {
        let isO2maticDeviceManager = false;
        if(userRoleLocations.length !== 0){
            userRoleLocations.forEach(role => {
                if(role.roleName === "DeviceManager" && role.locationId === 21){
                    isO2maticDeviceManager = true;
                }
            })
        }
        setIsO2maticDeviceManager(isO2maticDeviceManager);
    },[userRoleLocations])

    useEffect(() => {
        (async () => {
            let deviceResult = await DeviceService.getDeviceByUdsn(udsn);
            setDevice(deviceResult.data);
            setMac(deviceResult.data.children?.[0]?.children[0]?.metaData?.find(x => x.metadataId == 16)?.value)
            setPuid(deviceResult.data.children?.[0]?.children[0]?.metaData?.find(x => x.metadataId == 3)?.value)
            setLoadingDevice(true);
        })();

        (async () => {
            let values = await MetadataService.getValues();
            setMetadataValues(values.data);

            setDmrList(values.data.filter(x => x.metaData.id == 11))
            setSwList(values.data.filter(x => x.metaData.id == 14))
            setFwList(values.data.filter(x => x.metaData.id == 15))
            //setStatusList(statuses)
            setStatusList(values.data.filter(x => x.metaData.id == 2))
            setMetadataLoading(true);

        })();

        (async () => {
            let apiResult = await LocationService.getByRoles(["DeviceManager"]);
            setLocations(apiResult.data.sort((a, b) => a.name > b.name ? 1 : -1));
            setloadingLocation(true);
        })();
    }, [])

    const handleLocationChange = (event, newValue) => {
        setLocation(newValue.props.value);
    };
    const handleOwnerChange = (event, newValue) => {
        setOwner(newValue.props.value);
    };
    const handleDmrChange = (event, newValue) => {
        setDmr(newValue.props.value);
    };
    const handleSwChange = (event, newValue) => {
        setSw(newValue.props.value);
    };
    const handleFwChange = (event, newValue) => {
        setFw(newValue.props.value);
    };
    const handleStatusChange = (event, newValue) => {
        setStatus(newValue.props.value);
    };
    const handleRemarkChange = (event, newValue) => {
        setRemark(event.target.value);
    };
    const handleMacChange = (event, newValue) => {
        setMac(event.target.value);
    };
    const handlePuidChange = (event, newValue) => {
        setPuid(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        DeviceService.updateHotDevice(device.id,
            {
                "id": device.id,
                "softwareVersionId": sw,
                "firmwareVersionId": fw,
                "dmrMetadataId": dmr,
                "statusId": status,
                "locationId": location,
                "ownerId": owner,
                "log": ((remark=="")? null:("Remarks: "+ remark)),
                "mac": mac,
                "puid": puid
            }
        ).then(() => {
            handleAlertBox(true, 200, null, null)
            setTimeout(() => {
                navigate("/device/HOT/" + udsn)
            }, 2000)
        }).catch(err => {
            console.log({ err: err })
            handleAlertBox(true, err?.response?.status, null, err?.response?.data?.join(","))
        })
    }


    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="HotDeviceEdit" /></PageHeader>
            <Box sx={{ mt: 2, width:{xs:"100%", sm:"400px"}}}>
                {/* <Typography>ID: {device.id}</Typography>
                <Typography>UDSN: {device.metaData?.find(x => x.metadataId == 1)?.value}</Typography>
                <Typography>PUID: {device.children?.[0]?.children[0]?.metaData?.find(x => x.metadataId == 3)?.value}</Typography>
                <Typography>MAC: {device.children?.[0]?.children[0]?.metaData?.find(x => x.metadataId == 16)?.value}</Typography>

                <Typography>DMR: {device.metaData?.find(x => x.metadataId == 11)?.value}</Typography>
                <Typography>SW: {device.metaData?.find(x => x.metadataId == 14)?.value}</Typography>
                <Typography>FW: {device.metaData?.find(x => x.metadataId == 15)?.value}</Typography>
                <Typography>Status: {device.metaData?.find(x => x.metadataId == 2)?.value}</Typography>


                <Typography>Owner: {device?.owner?.name}</Typography>
                <Typography>Location: {device?.location?.name}</Typography> */}

                <PatientDetailText
                    label={<Translate value="Id" />}
                    value={device.id}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="UDSN" />}
                    value={device.metaData?.find(x => x.metadataId == 1)?.value}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="PUID" />}
                    value={device.children?.[0]?.children[0]?.metaData?.find(x => x.metadataId == 3)?.value}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="MAC" />}
                    value={device.children?.[0]?.children[0]?.metaData?.find(x => x.metadataId == 16)?.value}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Dmr" />}
                    value={device.metaData?.find(x => x.metadataId == 11)?.value}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Sw" />}
                    value={device.metaData?.find(x => x.metadataId == 14)?.value}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Fw" />}
                    value={device.metaData?.find(x => x.metadataId == 15)?.value}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="Status" />}
                    value={device.metaData?.find(x => x.metadataId == 2)?.value}
                ></PatientDetailText>

                <PatientDetailText
                    label={<Translate value="DeviceOwner" />}
                    value={device?.owner?.name}
                ></PatientDetailText>
                <PatientDetailText
                    label={<Translate value="DeviceLocation" />}
                    value={device?.location?.name}
                ></PatientDetailText>
                <br/>

                {loadingDevice ?
                    <Box component="form" onSubmit={handleSubmit}>
                        {/* <InputLabel id="dmrLabel"><Translate value="Dmr" /></InputLabel>
                        {metadataLoading ?
                            <Select
                                required
                                id="dmr"
                                value={dmr || device.metaData?.find(x => x.metadataId == 11)?.id}
                                onChange={handleDmrChange}
                                sx={{ width:"100%" }}
                            >
                                {dmrList.map(value => {
                                    return <MenuItem key={value.id} value={value.id}>{value.value}</MenuItem>
                                })}
                            </Select> : <span><Translate value="Loading" /></span>
                        } */}

                        <InputLabel id="PuidLabel"><Translate value="PUID" /></InputLabel>
                        <TextField sx={{ width:"100%" }} id="outlined-basic"
                            inputProps={{
                                pattern: "^[a-fA-F0-9]{16}$",
                            }} placeholder={translate("PUID")} variant="outlined" value={puid} onChange={handlePuidChange} />

                        <InputLabel id="MacLabel"><Translate value="MAC" /></InputLabel>
                        <TextField sx={{ width:"100%" }} id="outlined-basic" inputProps={{
                            pattern: "^([0-9A-Fa-f]{12})$",
                        }} placeholder={translate("MAC")} variant="outlined" value={mac} onChange={handleMacChange} />

                        <InputLabel id="swLabel"><Translate value="Sw" /></InputLabel>
                        {metadataLoading ?
                            <Select
                                required
                                id="sw"
                                value={sw || device.metaData?.find(x => x.metadataId == 14)?.id}
                                onChange={handleSwChange}
                                sx={{ width:"100%" }}
                            >
                                {swList.map(value => {
                                    return <MenuItem key={value.id} value={value.id}>{value.value}</MenuItem>
                                })}
                            </Select> : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="fwLabel"><Translate value="Fw" /></InputLabel>
                        {metadataLoading ?
                            <Select
                                required
                                id="fw"
                                value={fw || device.metaData?.find(x => x.metadataId == 15)?.id}
                                onChange={handleFwChange}
                                sx={{ width:"100%" }}
                            >
                                {fwList.map(value => {
                                    return <MenuItem key={value.id} value={value.id}>{value.value}</MenuItem>
                                })}
                            </Select> : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="statusLabel"><Translate value="Status" /></InputLabel>
                        {metadataLoading ?
                            <Select
                                required
                                id="status"
                                value={status || device.metaData?.find(x => x.metadataId == 2)?.id}
                                onChange={handleStatusChange}
                                disabled={!isO2maticDeviceManager}
                                sx={{ width:"100%" }}
                            >
                                {statusList.map(value =>{
                                    if(value.value==="AtRepairO2matic"||value.value==="SCRAPPED"||value.value==="ReadyForInvestigation"||value.value==="ReadyForTesting"||value.id===device.metaData?.find(x => x.metadataId == 2)?.id){
                                        return <MenuItem key={value.id} value={value.id}>{value.value}</MenuItem>
                                    }
                                    //return <MenuItem disabled key={value.id} value={value.id}>{value.value}</MenuItem> // commented out to hide the non-selectable statuses
                                })}
                            </Select> : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="ownerLabel"><Translate value="DeviceOwner" /></InputLabel>
                        {loadingLocation ?
                            <Select
                                required
                                id="owner"
                                value={owner || device?.owner?.id}
                                onChange={handleOwnerChange}
                                sx={{ width:"100%" }}
                            >
                                {locations.map(location => {
                                    return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                                })}
                            </Select>
                            : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="locationLabel"><Translate value="DeviceLocation" /></InputLabel>
                        {loadingLocation ?
                            <Select
                                required
                                id="location"
                                value={location || device?.location?.id}
                                onChange={handleLocationChange}
                                sx={{ width:"100%" }}
                            >
                                {locations.map(location => {
                                    return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                                })}
                            </Select>
                            : <span><Translate value="Loading" /></span>
                        }

                        <InputLabel id="remakrsLabel"><Translate value="Remarks" /></InputLabel>
                        <TextField sx={{ width:"100%" }} id="outlined-basic" inputProps={{ maxLength: 250 }} placeholder={translate("Remarks")} variant="outlined" value={remark} onChange={handleRemarkChange} />
                    <br/>
                        <Button sx={{ mt: 2, width:"200px"}} color="success" variant="contained" type="submit" startIcon={<SaveIcon />}>
                            <Translate value="Save" />
                        </Button>
                        <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox} />

                    </Box>

                    : <span><Translate value="Loading" /></span>
                }





            </Box>
        </PageContainer>
    )
}
