import { Translate } from "react-i18nify";
import { Box, Typography } from "@mui/material";
import { fil } from "date-fns/locale";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloseIcon from "@mui/icons-material/Close";
export const DropZone = (props) => {
  const [files, setFiles] = useState([]);
  const types = [...props.types];


  useEffect(()=>{
    if(props.clear){
      setFiles([])
    }
  },[props.clear])

  useEffect(()=>{
    props.onData(files)
  },[files])

  const onDelete=(fileName)=>{
    setFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter((file) => file.name !== fileName);
        return updatedFiles;
      });
  }
  


  const onDrop = useCallback((acceptedFiles) => {
    if (types.length == 0) {
      acceptedFiles.forEach((file) => {
        console.log(file);
        if (props.type)
          setFiles((prev) => {
            return [...prev, file];
          });
      });
    } else {
      acceptedFiles.forEach((file) => {
        console.log(file);
        var fileType=file.name.split(".").pop();
        console.log(fileType)
        if (types.includes(fileType))
          setFiles((prev) => {
            return [...prev, file];
          });
      });
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <Box
      {...getRootProps()}
      sx={{
        width: props.width,
        minHeight: props.height,
        display: "flex",
        cursor: "pointer",
        borderRadius: "10px",
        border: "3px dotted blue",
        borderColor: "primary.main",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <input {...getInputProps()} />
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "100px",
          }}
        >
          <Translate value = "DragNDropFile" />
        </Typography>
        <Box
          sx={{
            width: props.width,
            display: "flex",
            position: "relative",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {files.map((element, index) => {
            return (
              <FilePreview name={element?.name} key={index} onDelete={(name)=>onDelete(name)} />
              //<Box sx={{width:"50px",height:"50px",background:"blue"}}>{element.name}</Box>
            );
          })}
        </Box>
      </div>
    </Box>
  );
};

const FilePreview = ({name, onDelete }) => {
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        onDelete(name);
      }}
      sx={{
        display: "flex",
        width: "75px",
        height: "100px",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CloseIcon sx={{ position: "absolute", top: 0, right: 5 }} />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <InsertDriveFileIcon
          sx={{ width: "50px", height: "75px", padding: 0, margin: 0 }}
          fontSize="large"
          color="primary.main"
        />
        <Typography
          component={"span"}
          sx={{
            width: "75px",
            textAlign: "center",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
