import { Translate , translate } from "react-i18nify"
import { PageHeader } from "../../../../components/Layout/PageHeader"
import PageContainer from "../../../../components/PageContainer"
import { Box, Typography, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { UserService, DeviceService, TemplateService } from "../../../../services/DataService"
import { useNavigate, useParams } from "react-router-dom"

export const RepairOverview = (props)=>{

    const [devicesProd, setDevicesProd] = useState([]);
    const [devicesO2matic, setDevicesO2matic] = useState([]);
    const [userRoleLocations, setUserRoleLocations] = useState([]);
    const [isProdRepairer, setIsProdRepairer] = useState(false);
    const [isO2maticRepairer, setIsO2maticRepairer] = useState(false);
    const { type } = useParams();
    const navigate = useNavigate();

    const [loadingProd, setLoadingProd] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            let getTemplatesResultProd = await TemplateService.getAll();
            let templateProd=getTemplatesResultProd.data.find(i=>i.code==type);
            let apiResultProd = await DeviceService.getByTemplate(templateProd.id, 0, 500,"ReadyForRepair",["Repairer"]);
            setDevicesProd(apiResultProd.data);
            setLoadingProd(false)
        })();
    }, [])

    

    useEffect(() => {
        (async () => {
            let getTemplatesResult = await TemplateService.getAll();
            let template=getTemplatesResult.data.find(i=>i.code==type);
            let apiResult = await DeviceService.getByTemplate(template.id, 0, 500,"AtRepairO2matic",["Repairer"]);
            let data=apiResult.data;
            setDevicesO2matic(data);
            setLoading(false)
        })();
    }, [])

    // get locations of user
    useEffect(() => {
        (async () => {
            await UserService.getActiveUserRolesLocations()
            .then((data) => {
                setUserRoleLocations(data.data);
            }).catch((error) => {
                console.log(error);
            })
        })();
    }, [])

    // get locations of user with repairer role
    useEffect(() => {
        let isProdRepairer = false;
        let isO2maticRepairer = false;
        if(userRoleLocations.length !== 0){
            userRoleLocations.forEach(role => {
                if(role.roleName === "Repairer" && role.locationId === 21){
                    isO2maticRepairer = true;
                }
                if(role.roleName === "Repairer" && role.locationId === 22){
                    isProdRepairer = true;
                }
            })
        }
        setIsO2maticRepairer(isO2maticRepairer);
        setIsProdRepairer(isProdRepairer);
        console.log("isProdRepairer", isProdRepairer);
        console.log("isO2maticRepairer", isO2maticRepairer);
    },[userRoleLocations])


    const columns = [

        {
            field: 'udsn', headerName: 'UDSN', flex: 1, 
            valueGetter: (udsn) => {
                return udsn.row.metaData.find(x => x.metadataId == 1)?.value;
            }
        },
        {
            field: 'status', headerName: translate("Status"), flex: 1, 
            valueGetter: (status) => {
                return status.row.metaData.find(x => x.metadataId == 2)?.value;
            }
        },
    ];

    const goToDeviceDetails = (params, event) => {
        let row = params.row;
        var udsn = row.metaData.find(x => x.metadataId == 1).value; 
        let path = udsn;
        navigate(path);
    };

    


    return(
        <PageContainer>
            <PageHeader leftAlign>
                <Translate value="Repair" />
            </PageHeader>

            {isProdRepairer?
            <>
            <Typography sx={{ mt: 1 }} variant="body">
                <Translate value="RepairProdDescription" />
            </Typography>
            <Box sx={{ width: "100%" }}>
                <DataGrid
                    ColumnWidth="*"
                    sx={{ height: 300, mt: 2 }}
                    columns={columns}
                    rows={devicesProd}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowClick={goToDeviceDetails}
                    loading={loadingProd}
                    components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            <Translate value="NoRowsDevices" />
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <Translate value="NoResults" />
                            </Stack>
                        )
                    }}
                />
            </Box>
            </>
            : null }

            {isO2maticRepairer?
            <>
            <Typography sx={{ mt: 4, }} variant="body">
                <Translate value="RepairO2maticDescription" />
            </Typography>
            <Box sx={{ width: "100%" }}>
                <DataGrid
                    ColumnWidth="*"
                    sx={{ height: 300, mt: 2 }}
                    columns={columns}
                    rows={devicesO2matic}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowClick={goToDeviceDetails}
                    loading={loading}
                    components={{
                        NoRowsOverlay: () => (
                          <Stack height="100%" alignItems="center" justifyContent="center">
                            <Translate value="NoRowsDevices" />
                          </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                              <Translate value="NoResults" />
                            </Stack>
                        )
                    }}
                />
            </Box>
            </>
            : null }

        </PageContainer>
    )
}