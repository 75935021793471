import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Typography from "@mui/material/Typography";
import PageContainer from "../../components/PageContainer";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { UserService, LocationService, RoleService } from "../../services/DataService";
import { PageDetailText } from "../../components/PageDetailText";
import { PageHeader } from "../../components/Layout/PageHeader";
import { CheckBox, Delete as DeleteIcon } from "@mui/icons-material";
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import { Translate, translate } from 'react-i18nify';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ConfirmDialog } from '../../components/Layout/Dialog/ConfirmDialog';
import AlertBox from "../../components/AlertBox";
import PatientDashboardCategory from "../../components/Patient/PatientDashboardCategory";
import { PatientDetailText } from "../../components/Patient/PatientDetailText";
import { Navigate } from "react-router";
import { ro } from "date-fns/locale";
import {UpdatesBanner} from "../../components/UpdatesBanner";

const UserDetail = () => {

    const { id } = useParams();
    const [user, setUser] = useState();
    // const [rows, setRows] = useState([]);
    const [rowId, setRowId] = useState(100);
    const [locations, setLocations] = useState([]);
    const [selectableLocations, setSelectableLocations] = useState([]);
    const [roles, setRoles] = useState([])
    const [rolesGeneral, setRolesGeneral] = useState([])
    const [rolesProduction, setRolesProduction] = useState([])
    const [rolesO2matic, setRolesO2matic] = useState([])
    const [rolesLocMatrix, setRolesLocMatrix] = useState([])
    const [roleLocations, setRoleLocations] = useState([])
    let rolesMatrix = [];
    const [editing, setEditing] = useState(false);
    const [addingLocation, setAddingLocation] = useState(false);
    const [selectedLocationToAdd, setSelectedLocationToAdd] = useState(null);
    const [rolesToBeChanged, setRolesToBeChanged] = useState([]);
    const [productionRolesAllowed, setProductionRolesAllowed] = useState(false);
    const [confirmStatus, setConfirmStatus] = useState({status: 0, body: null});
    const [redirect, setRedirect] = useState(false);
    const arrayColumn = (arr, n) => arr.map(x => x[n]); // function to get the first column of the rolesMatrix (location ids)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const navigate = useNavigate();

    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    });

    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }

    const dismissAlertBox = () => {
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null
        })
    }

    const getLocationPropertyNames = (locations) => {
        let locationPropertyNames = ["HUMAN"];
        const o2matiTag = locations.find((element) => element.tags.includes("O2MATIC"))?.tags?.find(tag => tag === "O2MATIC")
        const productionTag = locations.find((element) => element.tags.includes("PRODUCTION"))?.tags?.find(tag => tag === "PRODUCTION")
        if(o2matiTag !== undefined)
            locationPropertyNames.push(o2matiTag)
        if(productionTag !== undefined)
            locationPropertyNames.push(productionTag)
        return locationPropertyNames;
    
    }

    useEffect(() => {
        (async () => {

            let apiResult = await UserService.get(id);
            let user = apiResult.data;
            let getLocations = await LocationService.getAll();
            let locations = [];
            let userLocations = [];
            getLocations.data.forEach(element => {
                locations.push({ value: element.id, label: element.name, tags: element.locationProperties.map(property => property.value) })
            });
            let roles = [];
            let locationPropertyNames = getLocationPropertyNames(locations);
            let getRoles = await RoleService.getByRoleNames(locationPropertyNames.toString());
            getRoles.data.forEach(element => {
                roles.push({ value: element.id, label: element.name, roleTypeId: element.roleTypeId  })
            });
        
            locations.sort((a, b) => a.label.localeCompare(b.label))
            locations.unshift({ value: 0, label: translate("NotSelected"), tags: [] })
            setLocations(locations);
            setRoles(roles)
            setUser(user);

            // get unique locations, add these as rows and add false to all roles in matrix
            user.roleLocations.forEach(element => {
                // used locations
                let usedLocId = [];
                if (rolesLocMatrix) {
                    usedLocId = arrayColumn(rolesLocMatrix, 0)
                    setRoleLocations(usedLocId) // set the ids of the locations that have roles
                }
                if (usedLocId.includes(element.locationId)) { // if location already exists
                    let locIdIndex = usedLocId.indexOf(element.locationId);
                    rolesLocMatrix[locIdIndex][element.roleId] = true;
                } else {
                    let rolesMatrixRow = [element.locationId,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];
                    rolesMatrixRow[element.roleId] = true;
                    rolesLocMatrix.push(rolesMatrixRow);
                }
            })
            //set roles here?
            setRolesLocMatrix(rolesLocMatrix)
        })();
        return () => {
            setUser();
        };
    }, [])

    useEffect(() => {
        (async () => {
            if (user == null || user == undefined || user.roleLocations == undefined || user?.roleLocations.length == 0) {
                return;
            }
            let allRoleLocationsForUser = user?.roleLocations;
            
            allRoleLocationsForUser.map((roleLocation, index) => {
                roleLocation.id = rowId + index;
                return roleLocation;
            })
            const lastItem = [...allRoleLocationsForUser].pop()
            allRoleLocationsForUser.reverse()
            /* setRows(allRoleLocationsForUser) */
            setRowId((currentId) => { return lastItem.id + 1 });

            // check is unique role O2maticAdministrator is present
            /* user.roleLocations.forEach(role => {
                if(role.roleId == 19){
                    setO2maticAdministratorRolePresent(true)
                }
            }) */
        })();
        return () => {

        };
    }, [user])

    useEffect(() => {
        let selectableLocationsArray = [];
        // remove locations that already have roles
        locations.forEach((location) => {
            if (!roleLocations.includes(location.value)) { // if location does NOT already have roles
                selectableLocationsArray.push(location)
            }
        });
        setSelectableLocations(selectableLocationsArray)
    }, [roleLocations])


    const handleDelete = () => {
        // opens the delete dialog
        setShowDeleteDialog(true)
    }
    const handleDeleteClose = () => {
        // closes the delete dialog
        setShowDeleteDialog(false)
    }

    const handleAddLocation = () => {
        setAddingLocation(true)
    }

    const handleLocationToAddSelected = (event) => {
        // get location id from event
        let locId = event.target.value
        let rolesMatrixRow = [locId,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];
        rolesLocMatrix.push(rolesMatrixRow);
        roleLocations.push(locId)
        setRoleLocations(roleLocations)
        setRolesLocMatrix(rolesLocMatrix); // update the roles matrix
        setAddingLocation(false) // close the add location box
        // KAC TODO get table to update
        let newSelectableLocations = selectableLocations.filter((location) => location.value !== locId)
        setSelectableLocations(newSelectableLocations)
        //add back if locations is removed (this is not possable yet)
    }


    const handleSave = async() => { // new table
        // saves changes to the roles
        if (rolesToBeChanged.length > 0) {
            await RoleService.updateRoles(rolesToBeChanged).then((response) => {
                handleAlertBox(true, response.status, null, null)
                setRolesToBeChanged([])
            }).catch((response) => {
                handleAlertBox(true, response.response.status, null, null)
                setTimeout(()=>{
                    navigate(0);//this refeshes the page
                },1000)
            }).finally(() => {
                setEditing(false)
            });
        }
        
    }

    const deleteUser = async () => {
        setConfirmStatus({status: 1, body: null})
        await UserService.delete(user?.id).then((data) => {
            setConfirmStatus({status: 2, body: data})
            setTimeout(() => {
                navigate("/user", { replace: true });
            }, 1000)
        }).catch((data) => {
            setConfirmStatus({status: 3, body: data.response})
        })
    }

    const handleCheckBoxChange = (event) => {
        let checked = event.target.checked;
        let locid = event.target.id;
        let roleid = event.target.value;

        let row = { "userId": id,"locationId": locid, "roleId": roleid, "isChecked": checked };
        let existingRole = rolesToBeChanged.filter((role) => role.roleId === roleid && role.locationId === locid) // check if role already exists in rolesToBeChanged

        if (existingRole.length === 0) {
            // if no such role already exists, then add role
            rolesToBeChanged.push(row)
        } else {
            // if role already exists, then remove it
            var index = rolesToBeChanged.indexOf(existingRole[0]);
            if (index > -1) {
                rolesToBeChanged.splice(index, 1);
            }
        }
        setRolesToBeChanged(rolesToBeChanged) // update the roles to be changed

        let tempRolesLocMatrix = rolesLocMatrix
        tempRolesLocMatrix.map((row) => { // add role to the rolesLocMatrix
            if (row[0] == locid) {
                row[roleid] = checked;
            }
        });
        setRolesLocMatrix(tempRolesLocMatrix);
    };

    const handleEditing = () => {
        setEditing(true);
    }


/*     const getNewId = () => { // keep?
        let newId = rowId;
        setRowId(prevCount => prevCount + 1);
        return newId;
    } */

    
    const renderUser = (user) => {
        if (user == undefined)
            return <Typography><Translate value="Loading" /></Typography>

        return <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"}}}>
            <Box sx={{ width:"100%-30px", mt: 1, borderRadius: "15px", border: 1, borderColor: "primary.main", bgcolor: "primary.main", color: "primary.contrastText", p: 2, display: "flex", justifyContent: 'space-between', flexDirection: "column"}}>
            
                <Typography variant="body">
                    {<Translate value="UserName" />}
                </Typography>
                <PatientDashboardCategory // patient name
                    title={`${user.name} ${user.lastName}`}
                >
                </PatientDashboardCategory>
            
                <Box sx={{mt:2, width:"60%"}}>
                <PatientDetailText
                    label={<Translate value="Id" />}
                    value={user.id}
                ></PatientDetailText>
                <PatientDetailText
                    label={translate("Email")}
                    value={user.email}
                ></PatientDetailText>
                <PatientDetailText
                    label={translate("Location")}
                    value={user.location.name}
                ></PatientDetailText>
                </Box>
            </Box>

        </Box>

    }

    const AddCheckboxes = () => {
        let rowHeight = "15px";

        // location name and checkboxes
        let locationTab = [];
        locWithRoles.forEach((locId) => { // for each location
            let locName = locations.find(l => l.value === locId).label
            let matrixId = roleLocations.indexOf(locId)

            let checkedState = false
            let checkBoxes = [];
            {(locId === 21)? // if locId = O2matic location -> add all roles
                roles.forEach((role, index) => { // for each role
                    let bgcolor = (index % 2 == 0) ? "white" : "grey.300"
                    if (matrixId !== -1) {
                        checkedState = rolesLocMatrix[matrixId][role.value]
                    }
                    (role.value !== 19)? // if role is not O2maticAdministrator -> add checkbox
                        checkBoxes.push(
                        <Box sx={{backgroundColor:bgcolor, borderRight: 1, borderBottom: 1, borderColor: "#000000", p:2, pl:7.5, height:rowHeight, dispay:"flex", justifyContent: 'center', alignContent: 'center'}}>
                            <Checkbox onChange={handleCheckBoxChange} defaultChecked={checkedState} disabled={!editing} id={locId} value={role.value} sx={{p:0 }}/>
                        </Box>
                        )
                    :
                        checkBoxes.push(
                        <Box sx={{backgroundColor:bgcolor, borderRight: 1, borderBottom: 1, borderColor: "#000000", p:2, pl:7.5, height:rowHeight, dispay:"flex", justifyContent: 'center', alignContent: 'center'}}>
                            <Checkbox defaultChecked={checkedState} disabled={true} sx={{p:0 }}/>
                        </Box>
                        )
                })
            :
            <>
            {(locId === 22)? // if locId = Alpha location -> add all roles but CtManager
            roles.forEach((role, index) => { // for each role
                let bgcolor = (index % 2 == 0) ? "white" : "grey.300"
                let CtManagerId = 11;
                // if (role.value !== CtManagerId && role.roleTypeId !== 5) { // for all roles but CtManager -> add checkbox
                if (role.roleTypeId !== 5) { // for all roles but CtManager -> add checkbox
                if (matrixId !== -1) {
                        checkedState = rolesLocMatrix[matrixId][role.value]
                    }
                    checkBoxes.push(
                    <Box sx={{backgroundColor:bgcolor, borderRight: 1, borderBottom: 1, borderColor: "#000000", p:2, pl:7.5, height:rowHeight, dispay:"flex", justifyContent: 'center', alignContent: 'center'}}>
                        <Checkbox onChange={handleCheckBoxChange} defaultChecked={checkedState} disabled={!editing} id={locId} value={role.value} sx={{p:0 }}/>
                    </Box>
                    )
                } else { // for CtManager role -> add empty box
                    checkBoxes.push(
                    <Box sx={{backgroundColor:bgcolor, borderRight: 1, borderBottom: 1, borderColor: "#000000", p:2, pl:7.5, height:rowHeight, dispay:"flex", justifyContent: 'center', alignContent: 'center'}}>
                    </Box>
                    )
                }
            })
            :
            // for all other locations -> add all roles but Repairer and CtManager
            roles.forEach((role, index) => { // for each role
                let bgcolor = (index % 2 == 0) ? "white" : "grey.300"
                let CtManagerId = 11;
                let RepairerId = 20;
                // if ((role.value !== CtManagerId) && (role.value !== RepairerId)) { // for all roles apart from CtManager and Repairer -> add checkbox
                if (role.roleTypeId !== 4 && role.roleTypeId !== 5) { // for all roles apart from O2matic and production roles -> add checkbox
                    if (matrixId !== -1) {
                        checkedState = rolesLocMatrix[matrixId][role.value]
                    }
                    checkBoxes.push(
                    <Box sx={{backgroundColor:bgcolor, borderRight: 1, borderBottom: 1, borderColor: "#000000", p:2, pl:7.5, height:rowHeight, dispay:"flex", justifyContent: 'center', alignContent: 'center'}}>
                        <Checkbox onChange={handleCheckBoxChange} defaultChecked={checkedState} disabled={!editing} id={locId} value={role.value} sx={{p:0 }}/>
                    </Box>
                    )
                } else { // for CtManager and Repairer roles -> add empty box
                    checkBoxes.push(
                    <Box sx={{backgroundColor:bgcolor, borderRight: 1, borderBottom: 1, borderColor: "#000000", p:2, pl:7.5, height:rowHeight, dispay:"flex", justifyContent: 'center', alignContent: 'center'}}>
                    </Box>
                    )
                }
            })
            }
            </>
            }

            // create tab for each location
            locationTab.push(
            <Box sx={{minWidth:"150px"}}>
                <Box sx={{fontWeight: 'bold', height:rowHeight,  borderRight: 1, borderTop: 1, borderBottom: 1, borderColor: "#000000", bgcolor: "primary.main", color: "primary.contrastText", p:2, display:"flex", alignContent:"center"}}>
                    {locName}
                </Box>
                {checkBoxes}
            </Box>
            )
        })



        // role names
        let rolesTab = [];
        roles.forEach((role, index) => {
            let bgcolor = (index % 2 == 0) ? "white" : "grey.300"
            rolesTab.push(
            <Box sx={{backgroundColor:bgcolor, borderRight: 1, borderLeft: 1, borderBottom: 1, borderColor: "#000000", p:2, height:rowHeight}}>
                <Translate value={role.label} />
            </Box>
            )
        })

        // empty checkboxes for when adding a new location
        let emptyCheckBoxes = [];
        roles.forEach((role, index) => {
            let bgcolor = (index % 2 == 0) ? "white" : "grey.300"
            emptyCheckBoxes.push(
            <Box sx={{backgroundColor:bgcolor, borderRight: 1, borderBottom: 1, borderColor: "#000000", p:2, pl:7.5, height:rowHeight, dispay:"flex", justifyContent: 'center', alignContent: 'center'}}>
                <Checkbox disabled={true} checked={false} sx={{p:0}} />
            </Box>
            )
        })
        // add tab for adding a new location
        let newLocationTab = [
            <Box sx={{width:"150px"}}>
                <Box sx={{borderRight: 1, borderTop: 1, borderBottom: 1, borderColor: "#000000", bgcolor: "primary.main", color: "primary.contrastText", p:2, height:rowHeight}}>
                <Select
                sx={{
                    width: "100%", boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, mt:-2,
                }}
                value={selectedLocationToAdd}
                label="Location"
                onChange={handleLocationToAddSelected}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Placeholder</em>;
                    }
        
                    return selected.join(', ');
                  }}
            >{
                    selectableLocations.map((location, index) => {
                        return <MenuItem key={index} value={location.value}>{location.label}</MenuItem>
                    })
                }
                </Select>
                </Box>
                {emptyCheckBoxes}
            </Box>
        ]

        return <Box sx={{display:"flex", flexDirection:"row", mt:3}}>
            <Box sx={{width:"280px"}}>
                <Box sx={{fontWeight: 'bold', border: 1, borderColor: "#000000", /* borderTopLeftRadius:15, */ bgcolor: "primary.main", color: "primary.contrastText", p:2, height:rowHeight}}>
                   <Translate value="Roles"/>
                </Box>
                {rolesTab}
            </Box>
            {locationTab}
            {addingLocation? newLocationTab : <></>}
        
        </Box>
    }

    let locWithRoles = arrayColumn(rolesLocMatrix, 0);

    return (
        <PageContainer>
            <PageHeader leftAlign><Translate value="UserDetails" /></PageHeader>

            <UpdatesBanner update_description="UpdatesDescriptionUserPage"/>

            {(user == undefined)?
            <></>
            :
            <>
            {renderUser(user)}
            <AddCheckboxes/>
                
            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-start' }, flexDirection: "column" , mt: 2}}>
                
                {editing?
                <Box sx={{display:"flex", flexDirection:"row"}}>
                    <Button  sx={{ width: "200px", mr: 2 }} variant="contained" startIcon={<AddIcon />} onClick={handleAddLocation}>
                        <Translate value="AddLocation" />
                    </Button>
                    <Button  sx={{ width: "200px", mr: 2 }} color="success" variant="contained" startIcon={<SaveIcon />} onClick={handleSave}>
                        <Translate value="SaveRoles" />
                    </Button>
                    <Button onClick={handleDelete} sx={{ width: "200px"}} color="error" variant="contained" startIcon={<DeleteIcon />}>
                        <Translate value="DeleteUser" />
                    </Button>
                </Box>
                :
                alertBox.visiblity? 
                    <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox} />
                :
                    <Button  sx={{ width: "200px"}} color="secondary" variant="contained" startIcon={<EditIcon />} onClick={handleEditing}>
                        <Translate value="EditRoles" />
                    </Button>
                }

                <ConfirmDialog
                    dialogIcon={<ErrorOutlineIcon color="error" sx={{ fontSize: 75 }} />}
                    title={<Translate value="PleaseConfirm" />}
                    message={<Translate value="DeleteUserText" />}
                    declineText={<Translate value="Close" />}
                    confirmText={<Translate value="Delete" />}
                    confirmButtonIcon={<DeleteIcon />}
                    open={showDeleteDialog}
                    onClose={handleDeleteClose}
                    onConfirm={deleteUser}
                    confirmStatus={confirmStatus}
                />
            </Box>
            </>
            }
        </PageContainer>
    );
};

export default UserDetail;
