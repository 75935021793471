import { Box, Button, Typography } from "@mui/material";
import AlertBox from "../../../components/AlertBox";
import PageContainer from "../../../components/PageContainer";
import { BatchTable } from "../../../components/Production/BatchTable";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DeviceService, LocationService } from "../../../services/DataService";
import { PageHeader } from "../../../components/Layout/PageHeader";
import { Roles } from "../../../redux/actions/auth";
import { DEVICE_TYPE } from "../Production";
import { Translate, translate } from "react-i18nify";
import { BatchTableWithLocation } from "../../../components/Production/BatchTableWithLocation";

export const Ship = (props)=>{
    const { udsn, type } = useParams();

    const [state, setState] = useState({});
    const navigate = useNavigate();
    const [submitState, setSubmitState] = useState(false);
  
    const [selectedDevices, setSelectedDevices] = useState({});
  
    const [devices, setDevices] = useState({});
    const [locations, setLocations] = useState([]);
  
    const [alertBox, setAlertBox] = useState({
      visibility: false,
      statusCode: null,
      text: null,
      error: null,
    });
  
    const handleAlertBox = (visibility, statusCode, text, error) => {
      setAlertBox({
        visibility: visibility,
        statusCode: statusCode,
        text: text,
        error: error,
      });
    };
  
    const dismissAlertBox = () => {
      setAlertBox({
        visibility: false,
        statusCode: null,
        text: null,
        error: null,
      });
    };
  
    useEffect(() => {
      (async () => {
        let templateId=-1;
        switch(type){
          case DEVICE_TYPE.PRO100.code:{
            templateId=1
            break;
          }
          case DEVICE_TYPE.HOT.code:{
            templateId=12
            break;
          }
  
        }
        let result = await DeviceService.getBatchByTemplate("ReleasedForSale", templateId,[Roles.Shipper]);

        let locationResult= await LocationService.getAll();
        console.log({ result: result });
        console.log({ locationResult: locationResult });
        setDevices(result.data);
        setLocations(locationResult.data.sort((a, b) => a.name.localeCompare(b.name)));
        console.log(locationResult.data); 
      })();
    }, []);
  

  
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log({ proSubmit: selectedDevices });
      let data = [];
      Object.keys(selectedDevices)?.forEach((batch) => {
        selectedDevices[batch].devices?.forEach((device) => {
          if(device.selected){
            data.push({id:device.device.id,locationId:device.locationId,ownerId:device.ownerId});
          }
        });
      });
      if(data.length==0){
        return;
      }
      setSubmitState(true);
        try {
          let result = await DeviceService.o2maticShipDevices(data);
  
          if (result.status >= 200 && result.status < 300) {
            handleAlertBox(true, result.status, translate("OwnerLocationChanged"), null);
          } else {
            handleAlertBox(true, result.status, result.statusText, null);
          }
          setTimeout(() => {
            navigate(`/production/${type}`);
          }, 2000);
        } catch (e) {
          handleAlertBox(true, e.status, e.statusText, e.message);
          setSubmitState(false);
        }
  
    };

    return (
      <PageContainer>
        <PageHeader leftAlign>
          <Translate value="Shipper" />
        </PageHeader>
        <Typography sx={{ mt: 1, mb: 1 }} variant="body">
          <Translate value="ShipperMessage" />
        </Typography>
        <Box sx={{width:"100%"}} component="form" onSubmit={handleSubmit}>


        <BatchTableWithLocation
          data={devices}
          locations={locations}
          onSelectedUpdate={(devices) => setSelectedDevices(devices)}
        />
        <Button
         disabled={submitState}
          sx={{ mt: 2 }}
          color="primary"
          type="submit"
          variant="contained"
        >
          <Translate value="Submit" />
        </Button>
        </Box>
  
        <AlertBox
          visible={alertBox.visibility}
          text={alertBox.text}
          error={alertBox.error}
          statusCode={alertBox.statusCode}
          onHide={dismissAlertBox}
        />
      </PageContainer>
    );
}