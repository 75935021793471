import { useEffect, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp, Translate } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
export const BatchTable = (props) => {
  const [selectAll, setSelectAll] = useState(false);
  const [state,newState]= useState({})

  

  const handleSelectAll = (event) => {
    let newValue=event.target.checked;
    setSelectAll(newValue);
    newState(prevState=>{
      const updatedState = { ...prevState };
      Object.keys(updatedState).forEach(batch=>{
        updatedState[batch].selected=newValue;
        updatedState[batch].devices.forEach(device=>{
          device.selected=newValue;
        })
        return updatedState;
      })
      return prevState;
    })
  };

  const handleBatchCheckboxChange = (event, batch) => {
    const newValue = event.target.checked;
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batch]) {
        updatedState[batch].selected = newValue;
        updatedState[batch].devices.forEach((device) => {
          device.selected = newValue;
        });
      }
      return updatedState;
    });
  };
  const handleExpendedChange = (event, batch) => {
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batch]) {
        updatedState[batch].expanded = !updatedState[batch].expanded;
      }
      return updatedState;
    });
  };

  const handleDeviceCheckboxChange = (event, batchNumber, id) => {
    const newValue = event.target.checked;
    newState((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState[batchNumber]) {
        const deviceIndex = updatedState[batchNumber].devices.findIndex(
          (device) => device.device.id === id
        );
        if (deviceIndex !== -1) {
          updatedState[batchNumber].devices[deviceIndex].selected = newValue;
        }
      }
      return updatedState;
    });
  };

  const createStateFromDevices=(batches)=>{
    if(batches==null||batches==undefined){
        return {}
    }
    let state={
    }
    Object.keys(batches).forEach(batch=>{
            console.log({batches});
            console.log({batch});

            let devices=[];
            batches[batch].forEach(device=>{
              devices.push({selected:false,device:device})
            })
            state[batch]={selected:false,expanded:false,devices:devices}
    })
    return state;
  }




  useEffect(()=>{
    let createState=createStateFromDevices(props.data)
    newState(createState);
    console.log({newState:newState})
  },[props.data])

  useEffect(()=>{
    props?.onSelectedUpdate(state);
  },[state])

  const Row = (props) => {
    const { row } = props;
    console.log({rowProps:props})

    const open=props.data.expanded;


    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <Checkbox checked={props.data.selected} onChange={(e)=>handleBatchCheckboxChange(e,props?.batch)} />
          </TableCell>
          <TableCell component="th" scope="row">
            {props?.batch}
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => handleExpendedChange(e,props?.batch)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Devices
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "50px" }}>Select</TableCell>
                      <TableCell>UDSN</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.data.devices.map((deviceState) => {
                      console.log({ device: deviceState });
                      return (
                        <TableRow key={deviceState?.device?.id}>
                          <TableCell>
                            <Checkbox checked={deviceState.selected} onChange={(e)=>handleDeviceCheckboxChange(e,props.batch,deviceState.device.id)} />
                          </TableCell>
                          <TableCell align="left">{deviceState.device?.udsn}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow align="left">
            <TableCell align="left" sx={{ width: "50px" }}>
              <Checkbox checked={selectAll} onChange={handleSelectAll} />
            </TableCell>
            <TableCell>Batch number</TableCell>
            <TableCell sx={{ width: "50px" }}>Expand</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {
            state!=null||state!=undefined?
            Object.keys(state)?.map(batch=>{
              return(
                <Row key={batch} batch={batch} data={state[batch]} />
              )
            }):null
          }
            
          {/* {props?.devices!=null?Object.keys(props?.devices)?.map((batch) => {
            return (
              <Row key={batch} batch={batch} data={props.devices[batch]} />
            );
          }):null} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
