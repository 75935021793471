import React, { useState, useEffect } from 'react'
import { LicenseService, RoleService } from '../../services/DataService'
import { LocationService } from '../../services/DataService';
import PageContainer from '../../components/PageContainer';
import { useNavigate } from "react-router-dom"
import { PageHeader } from '../../components/Layout/PageHeader';
import { TextField, InputLabel, Select, MenuItem, Button, Checkbox, Box, OutlinedInput, ListItemText, Chip, Typography} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import SaveIcon from '@mui/icons-material/Save';
import { useTheme } from '@emotion/react';
import { Translate, translate, Localize } from 'react-i18nify';
import AlertBox from '../../components/AlertBox';
import FormHelperDialogWrapper from '../../components/Layout/Dialog/FormHelperDialogWrapper';
export default function LicenseCreate() {

    const [roles, setRoles] = React.useState([]);
    const [location, setLocation] = useState("");
    const [locations, setLocations] = useState([]);
    const [loadingLocation, setloadingLocation] = useState(false);
    const [requestSubmited, setRequestSubmited] = useState(false);
    const availableRoles = [
        { "id": 8, "name": "InstallerTablet" },
        { "id": 9, "name": "PatientTablet" },
        { "id": 10, "name": "DetailedSessionSender" }
    ]

    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );


    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }
    const dismissAlertBox=()=>{
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }

    const theme = useTheme();
    let history = useNavigate();
    const getRoles = () => {
        return new Promise(resolve => {
            RoleService.getRoles().then(success => {
                resolve(success);
            }, err => {

            })
        })

    }
    const handleLocationChange = (event, newValue) => {
        setLocation(newValue.props.value);
    };


    useEffect(() => {
        (async () => {
            let apiResult = await LocationService.getByRoles(["Administrator", "DeviceManager"]);
            setLocations(apiResult.data.sort((a, b) => a.name > b.name ? 1 : -1));
            setloadingLocation(true);
        })();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        dismissAlertBox()
        setRequestSubmited(true)
        let roleList = [];
        roles.forEach(role => {

            let found = availableRoles.find(findRole => {
                return findRole.name == role;
            })

            roleList.push(found);
        });

        const data = new FormData(event.currentTarget);

        let name = data.get('name');

        LicenseService.create(name, roleList, location).then(success => {
            handleAlertBox(true, success.status, success.statusText, null) 
            
            setTimeout(()=>{
                history("/license");
            },1000)
        }, err => {
            setRequestSubmited(false)
            handleAlertBox(true, err?.response?.status, null, err?.response?.data?.join(",")) 
        })
    };

    const handleChange = (event) => {
        let stringRoles = event.target.value;
        setRoles(stringRoles);
    };

    const [open, setOpen] = useState(false);
    const toggleHelp = () => {
        setOpen(old => !old);
    };

    const helpIconTheme = {
		//mr: {md:3, xs: 28},
		color: open ? "primary.dark" : "neutral.800", 
		width:"18px",
	}




    return (
        <PageContainer>
            <PageHeader><Translate value="AddAppLicense" /></PageHeader>
            <Box sx={{display:"flex", flexDirection:"row",alignItems: 'center'}}>
                <Box
                    sx={{ width: "min-content" }}
                    component="form"
                    onSubmit={handleSubmit}>
                    <InputLabel sx={{ mt: 3 }} id="name"><Translate value="Name" /></InputLabel>
                    <TextField
                        required
                        labelid="name"
                        sx={{ mb: 0, width: 350 }}
                        margin="normal"
                        id="name"
                        label={translate("AppLicenseName")}
                        type="text"
                        name="name"
                        autoFocus />

                    <InputLabel sx={{ mt: 3 }} id="locationLabel"><Translate value="Location" /></InputLabel>
                    {loadingLocation ?
                        <Select
                            sx={{ width: 350 }}
                            required
                            id="location"
                            value={location || ""}
                            onChange={handleLocationChange}
                        >
                            {locations.map(location => {
                                return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                            })}
                        </Select>
                        : <span><Translate value="Loading" /></span>}



                    <Box sx={{display:"flex", justifyContent: 'space-between', mt:3}}>
                        <InputLabel id="labelSelectRole"><Translate value="SelectRoles" /></InputLabel>
                        <HelpIcon onClick={toggleHelp} sx={helpIconTheme} />
                    </Box>
                    
                    {/* <FormHelperDialogWrapper text={<Translate value="AppLicenseRolesDescription"/>}> */}
                    <Select
                        sx={{ mb: 0, width: 350, fontWeight: theme.typography.fontWeightRegular }}
                        labelid="labelSelectRole"
                        id="roles"
                        required
                        multiple
                        value={roles}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                        renderValue={(selected) => {
                            return (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )
                        }}
                    >
                        {console.log(roles)}
                        {availableRoles.map((role) => (
                            <MenuItem
                                key={role.id}
                                value={role.name}
                            >
                                <Checkbox checked={roles.indexOf(role.name) > -1} />
                                <ListItemText primary={translate(role.name)} />
                            </MenuItem>
                        ))}
                    </Select>
                    {/* </FormHelperDialogWrapper> */}

                    <Button
                        disabled={requestSubmited}
                        color="success"
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, width: 200 }}
                        startIcon={<SaveIcon />}>
                        <Translate value="Save" />
                    </Button>
                </Box>

                <Box sx={{width: "350px", marginLeft: "30px", display: open ? "flex" : "none", mt:7}}  >

                        <Box sx={{ p: 2, backgroundColor: "primary.light",  borderRadius: "15px", display:"flex", flexDirection:"column"}}>
                            {availableRoles.map((role) => {
                                return(
                                <Box key={role.id}>
                                <Typography variant="h6"><Translate value={role.name} /></Typography>
                                <Translate value={role.name +"Description"} />
                                </Box>
                                )
                            })}

                        </Box>

                </Box> 

            </Box>
            <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox}/>
        </PageContainer>
    )
}
