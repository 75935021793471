export const ProCSVType = {
    NORMAL: "NORMAL",
    TEST: "TEST"
}

export class ProCsvParser {

    constructor() {
        this.version = "UNDEFINED"
        this.patient = {
            id: "",
            name: "",
            location: "",
        }
        this.profile = {
            name: "",
            delay: "",
            spo2Acc: "",
            spo2High: "",
            o2Low: "",
            o2High: "",
            o2Init: "",
            pulseHigh: "",
            flowRate: "",
        }
        this.testProfile = {
            name: "",
            profile: "",
            spo2Acc: "",
            spo2High: "",
            o2Low: "",
            o2High: "",
            o2Init: "",
            warmUp: "",
            coolDown: "",
            duration: ""
        }
        this.meta = {
            type: ProCSVType.NORMAL,
            date: "",
            sampleCount: "",
            puid: "",
            hash: ""
        }
        this.average = {
            spo2: 0,
            pulse: 0,
            o2: 0
        }
        this.data = []
    }


    parseCSV = (csv, name) => {
        console.log("Parsing...")
        console.log({ csv })
        if (csv.data.length == 0) {
            console.log("NoData")
            return "NoData"
        }

        if (csv?.meta?.fields[0] === "Version") {
            //new file
            this.parseByVersionNumber(csv)
        }
        else if (csv?.meta?.fields[0] === "Test") {
            this.parseTest(csv, name);
        }
        else {
            // legacy file
            this.parseLegacy(csv)
        }
        return {
            meta: this.meta,
            data: this.data,
            profile: this.profile,
            testProfile: this.testProfile,
            patient: this.patient,
            version: this.version,
            average: this.average

        }
    }

    extractVersion = (inputString, targetVersion) => {
        var versionPattern = new RegExp(targetVersion, 'g');
        var extractedVersion = inputString.match(versionPattern);
        if (extractedVersion) {
            return extractedVersion[0];
        } else {
            return null;
        }
    }
    parseByVersionNumber = (csv) => {
        let headers = csv.meta.fields;
        let data = csv.data;

        let version = "Version"
        let versionData = data[0][version]

        if (this.extractVersion(versionData, "v1.7.0")||this.extractVersion(versionData, "v1.7.0.1")) {
            this.version = "v1.7.0.1"
            console.log("Parsing version:v1.7.0.1")
            this.parseGenericVersion(csv)
        }
        else {
            console.log("Version not supported")
        }
    }

    parseGenericVersion = (csv) => {
        let patientID = "PatientID"
        let patientName = "PatientName"
        let location = "Location"

        let profile = "Profile"
        let delay = "Delay"
        let spO2Acc = "SpO2Acc"
        let spO2High = "SpO2High"
        let o2Low = "O2Low"
        let o2High = "O2High"
        let o2Init = "O2Init"
        let pulseHigh = "PulseHigh"
        let flowRate = "FlowRate"

        let timeDate = "TimeDate"
        let samplesCount = "SamplesCount"
        let PUID = "PUID"
        let hash = "Hash"

        let data = csv.data;
        let headersData = data[0]
        this.patient = {
            id: parseInt(headersData[patientID]),
            name: headersData[patientName],
            location: headersData[location],
        }

        this.profile = {
            name: headersData[profile],
            delay: headersData[delay],
            spo2Acc: headersData[spO2Acc],
            spo2High: headersData[spO2High],
            o2Low: headersData[o2Low],
            o2High: headersData[o2High],
            o2Init: headersData[o2Init],
            pulseHigh: headersData[pulseHigh],
            flowRate: headersData[flowRate],
        }
        this.meta = {
            type: ProCSVType.NORMAL,
            date: headersData[timeDate],
            sampleCount: headersData[samplesCount],
            puid: headersData[PUID],
            hash: headersData[hash]
        }

        let collectedData = []
        let sample = []
        let spO2 = []
        let flow = []
        let pulse = []
        let mode = []
        let state = []
        let breakpoint = []
        let spo2Average = {
            value: 0,
            removeCounter: 0
        }
        let o2Average = 0
        let pulseAverage = {
            value: 0,
            removeCounter: 0
        }
        

        for (let i = 2; i < data.length - 1; i++) {
            let spo2Data = data[i].PatientName
            let flowData = data[i].Location
            let pulseData = data[i].Profile

            let sessionDate = new Date(headersData[timeDate]).addSeconds(parseInt(data[i].PatientID))
            sample.push(sessionDate.getTime() / 1000)
            if (spo2Data < 40) {
                spO2.push(null)
                spo2Average.removeCounter++
            }
            else {
                spO2.push(spo2Data)
                spo2Average.value += parseInt(spo2Data)
            }
            if (pulseData < 25) {
                pulse.push(null)
                pulseAverage.removeCounter++
            }
            else {
                pulse.push(pulseData) // KAC: fixed bug where data pushed was actually spo2Data: pulse.push(spo2Data)
                pulseAverage.value += parseInt(pulseData)
            }

            flow.push(flowData)
            state.push(data[i].Delay)
            breakpoint.push(data[i].SpO2Acc)
            o2Average += parseFloat(flowData)

        }
        spo2Average.value = spo2Average.value / (data.length - 3 - spo2Average.removeCounter)
        console.log({o2Average})
        o2Average = o2Average / (data.length - 3)
        pulseAverage.value = pulseAverage.value / (data.length - 3 - pulseAverage.removeCounter)
        this.average = {
            spo2: spo2Average.value,
            pulse: pulseAverage.value,
            o2: o2Average
        }
        collectedData.push(sample)
        collectedData.push(spO2)
        collectedData.push(flow)
        collectedData.push(pulse)
        collectedData.push(state)
        collectedData.push(breakpoint)
        this.data = collectedData

    }
    parseTest = (csv, name) => {
        let data = csv.data;
        let testData = data[0]
        let profileData = data[2]


        this.testProfile = {
            name: testData["Test"],
            profile: profileData["Duration"],
            spo2Acc: profileData["Warmup"],
            spo2High: profileData["Cooldown"],
            o2Low: profileData["__parsed_extra"][0],
            o2High: profileData["__parsed_extra"][1],
            o2Init: profileData["__parsed_extra"][2],
            pulseHigh: profileData["__parsed_extra"][3],
            flowRate: profileData["__parsed_extra"][4],
            warmUp: testData["Warmup"],
            coolDown: testData["Cooldown"],
            duration: testData["Duration"]
        }

        this.patient = {
            id: parseInt(profileData["Test"]),
            name: "",
            location: "",
        }
        let date = `${name?.slice(0, 4)}-${name?.slice(4, 6)}-${name?.slice(6, 8)} ${name?.slice(8, 10)}:${name?.slice(10, 12)}:${name?.slice(12, 14)}`;

        this.meta = {
            type: ProCSVType.TEST,
            date: date,
            sampleCount: "",
            puid: "",
            hash: ""
        }
        let collectedData = []
        let sample = []
        let spO2 = []
        let flow = []
        let pulse = []
        let spo2Average = {
            value: 0,
            removeCounter: 0
        }
        let o2Average = 0
        let pulseAverage = {
            value: 0,
            removeCounter: 0
        }

        for (let i = 4; i < data.length - 1; i++) {

            let spo2Data = data[i].Duration
            let flowData = data[i].Cooldown
            let pulseData = data[i].Warmup
            let sessionDate = new Date(date).addSeconds(parseInt(data[i].Test))

            sample.push(sessionDate.getTime() / 1000)

            if (spo2Data < 40) {
                spO2.push(null)
                spo2Average.removeCounter++
            }
            else {
                spO2.push(spo2Data)
                spo2Average.value += parseInt(spo2Data)
            }
            if (pulseData < 25) {
                pulse.push(null)
                pulseAverage.removeCounter++
            }
            else {
                pulse.push(pulseData) // KAC: fixed bug where data pushed was actually spo2Data: pulse.push(spo2Data)
                pulseAverage.value += parseInt(pulseData)
            }


            flow.push(flowData)

            o2Average += parseFloat(flowData)

        }
        spo2Average.value = spo2Average.value / (data.length - 5 - spo2Average.removeCounter)
        o2Average = o2Average / (data.length - 5)
        pulseAverage.value = pulseAverage.value / (data.length - 5 - pulseAverage.removeCounter)
        this.average = {
            spo2: spo2Average.value,
            pulse: pulseAverage.value,
            o2: o2Average
        }

        collectedData.push(sample)
        collectedData.push(spO2)
        collectedData.push(flow)
        collectedData.push(pulse)
        this.data = collectedData
    }

    parseLegacy = (csv) => {
        let patientID = "ID"
        let location = "Location"

        let profile = "Name"
        let delay = "Delay"
        let spO2Acc = "SpO2Acc"
        let spO2High = "SpO2High"
        let o2Low = "O2Low"
        let o2High = "O2High"
        let o2Init = "O2Init"
        let pulseHigh = "PulseHigh"
        let flowRate = "FlowRate"


        let timeBegin = "TimeBegin"
        let timeDate = "TimeDate"
        let samplesCount = "SamplesCount"
        let PUID = "UUID"
        let hash = "Hash"

        let data = csv.data;
        console.log(data)
        let headersData = data[0]
        this.patient = {
            id: parseInt(headersData[patientID]),
            name: "",
            location: headersData[location],
        }

        this.profile = {
            name: headersData[profile],
            delay: headersData[delay],
            spo2Acc: headersData[spO2Acc],
            spo2High: headersData[spO2High],
            o2Low: headersData[o2Low],
            o2High: headersData[o2High],
            o2Init: headersData[o2Init],
            pulseHigh: headersData[pulseHigh],
            flowRate: headersData[flowRate],
        }
        this.meta = {
            type: ProCSVType.NORMAL,
            date: headersData[timeDate],
            sampleCount: headersData[samplesCount],
            puid: headersData[PUID],
            hash: headersData[hash]
        }

        let collectedData = []
        let sample = []
        let spO2 = []
        let flow = []
        let pulse = []
        let state = []
        let breakpoint = []
        let spo2Average = {
            value: 0,
            removeCounter: 0
        }
        let o2Average = 0
        let pulseAverage = {
            value: 0,
            removeCounter: 0
        }
        let sessionTime = new Date(headersData[timeDate])
        let timebegin = headersData[timeBegin]

        for (let i = 2; i < data.length - 1; i++) {
            let spo2Data = data[i]["Name"]
            let flowData = data[i]["SpO2Acc"]
            let pulseData = data[i]["SpO2High"]
            let sampleSeconds = data[i]["Delay"] - timebegin
            let sampleDatetime = new Date(sessionTime)
            sampleDatetime = sampleDatetime.addSeconds(sampleSeconds - timebegin)
            if (spo2Data < 40) {
                spO2.push(null)
                console.log("spo2Average adding 0")
                spo2Average.removeCounter++
            }
            else {
                spO2.push(spo2Data)
                spo2Average.value += parseInt(spo2Data)
                console.log("spo2Average adding " + parseInt(spo2Data))
            }
            if (pulseData < 25) {
                pulse.push(null)
                pulseAverage.removeCounter++
            }
            else {
                pulse.push(pulseData) // KAC: fixed bug where data pushed was actually spo2Data: pulse.push(spo2Data)
                pulseAverage.value += parseInt(pulseData)
            }

            sample.push(sampleDatetime.getTime() / 1000)
            flow.push(flowData)
            state.push(data[i].Delay)
            breakpoint.push(data[i].SpO2Acc)
            o2Average += parseFloat(flowData)
        }
        console.log({o2Average})
        spo2Average.value = spo2Average.value / (data.length - 3 - spo2Average.removeCounter)
        o2Average = o2Average / (data.length - 3)
        console.log({o2Average})
        pulseAverage.value = pulseAverage.value / (data.length - 3 - pulseAverage.removeCounter)
        this.average = {
            spo2: spo2Average.value,
            pulse: pulseAverage.value,
            o2: o2Average
        }
        collectedData.push(sample)
        collectedData.push(spO2)
        collectedData.push(flow)
        collectedData.push(pulse)
        collectedData.push(state)
        collectedData.push(breakpoint)
        this.data = collectedData
    }
}