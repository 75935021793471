import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageContainer from "../../../components/PageContainer";
import { PageHeader } from "../../../components/Layout/PageHeader";
import { Translate } from "react-i18nify";
import { Box, Button, Typography } from "@mui/material";
import AlertBox from "../../../components/AlertBox";
import { DeviceService } from "../../../services/DataService";
import { BatchTable } from "../../../components/Production/BatchTable";
import { DEVICE_TYPE } from "../Production";
import { Roles } from "../../../redux/actions/auth";

export const Qa = (props) => {
  const { udsn, type } = useParams();

  const [state, setState] = useState({});
  const navigate = useNavigate();
  const [submitState, setSubmitState] = useState(false);

  const [selectedDevices, setSelectedDevices] = useState({});

  const [devices, setDevices] = useState({});

  const [alertBox, setAlertBox] = useState({
    visibility: false,
    statusCode: null,
    text: null,
    error: null,
  });

  const handleAlertBox = (visibility, statusCode, text, error) => {
    setAlertBox({
      visibility: visibility,
      statusCode: statusCode,
      text: text,
      error: error,
    });
  };

  const dismissAlertBox = () => {
    setAlertBox({
      visibility: false,
      statusCode: null,
      text: null,
      error: null,
    });
  };

  useEffect(() => {
    (async () => {

      let templateId=-1;
      switch(type){
        case DEVICE_TYPE.PRO100.code:{
          templateId=1
          break;
        }
        case DEVICE_TYPE.HOT.code:{
          templateId=12
          break;
        }

      }
      
      let result = await DeviceService.getBatchByTemplate("Packaged", templateId,[Roles.ProductionQa]);
      console.log({ result: result });
      setDevices(result.data);
    })();
  }, []);


  const handleSubmit = async () => {
    console.log({ proSubmit: selectedDevices });
    let data = [];
    Object.keys(selectedDevices)?.forEach((batch) => {
      selectedDevices[batch].devices?.forEach((device) => {
        if(device.selected){
          data.push(device.device.id);
        }
      });
    });
    if(data.length==0){
      return;
    }
    setSubmitState(true);
      try {
        let result = await DeviceService.qaApproveDevice(data);

        if (result.status >= 200 && result.status < 300) {
          handleAlertBox(true, result.status, "Devices QA Approved", null);
        } else {
          handleAlertBox(true, result.status, result.statusText, null);
        }
        setTimeout(() => {
          navigate(`/production/${type}`);
        }, 2000);
      } catch (e) {
        handleAlertBox(true, e.status, e.statusText, e.message);
        setSubmitState(false);
      }
  };

  return (
    <PageContainer>
      <PageHeader leftAlign>
        <Translate value="ProductionQAAprove" />
      </PageHeader>
      <Typography sx={{ mt: 1, mb: 1 }} variant="body">
        <Translate value="ProductionQAAproveMessage" />
      </Typography>
      <BatchTable
        data={devices}
        onSelectedUpdate={(devices) => setSelectedDevices(devices)}
      />
      <Button
      disabled={submitState}
        onClick={handleSubmit}
        sx={{ mt: 2 }}
        color="primary"
        variant="contained"
      >
        <Translate value="Submit"/>
      </Button>

      <AlertBox
        visible={alertBox.visibility}
        text={alertBox.text}
        error={alertBox.error}
        statusCode={alertBox.statusCode}
        onHide={dismissAlertBox}
      />
    </PageContainer>
  );
};
