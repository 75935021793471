import React, { useState, useLayoutEffect } from 'react'
import { Box } from '@mui/system'
import DownloadIcon from '@mui/icons-material/Download';
import { Dialog, Typography, Button, Link } from '@mui/material'
import { PatientDetailText } from './PatientDetailText'
import { translate, Translate, Localize } from 'react-i18nify';
import TimeUtils from '../../utils/TimeUtils';
import { useRef, useEffect } from 'react';
//import DetailedSessionChart from '../../components/Session/DetailedSessionChart'
//import DetailedSessionChart from '../Session/DetailSessionChartChartJS';
import { Chart } from '../Session/DetailedSessionChartUplot';
import { LogService } from '../../services/DataService';


const generateChartData = (data) => {
    try {
        const chartLength = data.reduce((total, batch) => total + batch.values.length, 0);
        const timestamp = Array.from({ length: chartLength }, () => null);
        const spo2Data = Array.from({ length: chartLength }, () => null);
        const pulseData = Array.from({ length: chartLength }, () => null);
        const o2Data = Array.from({ length: chartLength }, () => null);

        let currentIndex = 0;

        for (const batch of data) {
            const values = batch.values;
            const batchStartTime = new Date(TimeUtils.utcToLocal(batch.start, true)).getTime() / 1000;

            timestamp[currentIndex] = batchStartTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            for (const value of values) {
                const timestampDataPoint = new Date(TimeUtils.utcToLocal(value.timestamp, true)).getTime() / 1000;

                timestamp[currentIndex] = timestampDataPoint;
                spo2Data[currentIndex] = value.spO2;
                pulseData[currentIndex] = value.pulse;
                o2Data[currentIndex] = value.o2;

                currentIndex++;
            }

            const batchEndTime = new Date(TimeUtils.utcToLocal(batch.end, true)).getTime() / 1000;

            timestamp[currentIndex] = batchEndTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            currentIndex++;
        }

        const result = [timestamp, spo2Data, pulseData, o2Data];

        return result;
    } catch (error) {
        throw new Error('Error generating chart data: ' + error);
    }
};


const downloadCsv = (session,detailedSessions, sessionDevice) => {

    console.log({session})
    console.log({detailedSessions})

    let flowResponseValue=session.treatment.values.find(i=>i.code==="Ki").value;
    let flowResponse = "Custom (" + flowResponseValue + ")";

    if (flowResponseValue == 150) flowResponse = "Very fast";
    if (flowResponseValue == 100) flowResponse = "Fast";
    if (flowResponseValue == 25) flowResponse = "Normal";

    // pulse oximeter compliance
    let poCom = session?.treatment?.values.find(i => i.code === "OAD")?.value;
    poCom = 1200;
    console.log(poCom>(60*60))
    if (poCom > (60*60)) {
        poCom = translate("Always")
    } else {
        poCom = ((poCom /60).toString() + " " +translate("MinutesPerHour")) ;
    }

    const rows = [
        ["sep=,"],
        [translate("O2maticId"), detailedSessions[0].patientId],
        [],
        [translate("Treatment")],
        [translate("TreatmentName"), session?.treatment?.name || ''],
        [translate("TreatmentId"), session?.treatment?.id || ''],
        [translate("AnalysingPhase"),session?.treatment.values.DurLod1 == 300 ? translate("Yes") : translate("No")],
        [translate("FlowResponse"), flowResponse],
        //[translate("ShortTermLength"), (session?.treatment?.values.find(i => i.code === "DurLod1")?.value || '') / 60 + " " + translate("MinutesPerHour")],
        //[translate("OxygenPrescription"), (session?.treatment?.minutesPrescribed || '') / 60 + " " + translate("hour/day")],
        [translate("O2ComplianceGoal"), (session?.treatment?.minutesPrescribed || '') + " " + translate("hours/day")],
        [translate("SpO2Range"), (session?.treatment?.values.find(i => i.code === "LSpO2")?.value || '') + " - " + (session?.treatment?.values.find(i => i.code === "HSpO2")?.value || '') + " %"],
        //[translate("LtO2Range"), (session?.treatment?.values.find(i => i.code === "LO2LT")?.value || '') + " - " + (session?.treatment?.values.find(i => i.code === "HO2LT")?.value || '') + " " + translate("l/min")],
        [translate("O2RangeWithoutPO"), (session?.treatment?.values.find(i => i.code === "LO2LT")?.value || '') + " - " + (session?.treatment?.values.find(i => i.code === "HO2LT")?.value || '') + " " + translate("l/min")],
        //[translate("StO2Range"), (session?.treatment?.values.find(i => i.code === "LO2ST")?.value || '') + " - " + (session?.treatment?.values.find(i => i.code === "HO2ST")?.value || '') + " " + translate("l/min")],
        [translate("O2RangeWithPO"), (session?.treatment?.values.find(i => i.code === "LO2ST")?.value || '') + " - " + (session?.treatment?.values.find(i => i.code === "HO2ST")?.value || '') + " " + translate("l/min")],
        [translate("AllowedTimeWithin") + " " + (session?.treatment?.values.find(i => i.code === "LO2ST")?.value || '') +  " - " + (session?.treatment?.values.find(i => i.code === "LO2LT")?.value || '') + " " + translate("and") + " " + (session?.treatment?.values.find(i => i.code === "HO2LT")?.value || '') +  " - " + (session?.treatment?.values.find(i => i.code === "HO2ST")?.value || '') + " " + translate("l/min") + " " + translate("withPulseOximeterOn"), poCom || ''],
        [],
        [translate("Device")],
        [translate("DeviceSn"), sessionDevice || ""],
        [],
        [translate("Session")],
        [translate("SessionId"), session?.deviceSessionId || ''],
        [translate("LengthOfSession"), TimeUtils.dateDiffToHHMMSS(new Date(session?.parts?.end?.timestamp), new Date(session?.parts?.start?.timestamp)) || ''],
        [],
        [translate("Start")],
        [translate("Timestamp"), TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(session?.parts?.start?.timestamp))) || ''],
        [translate("InitialSpO2"), session?.parts?.start?.values.SpO2Initial + " %" || ''],
        [translate("InitialPulse"), session?.parts?.start?.values.PulseInitial + " " + translate("bpm") || ''],
        [translate("InitialO2Flow"), session?.parts?.start?.values.OxygenInitial + " " + translate("l/min") || ''],
        [],
        [translate("Analyzing")],
        [translate("Timestamp"), TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(session?.parts?.analyzing?.timestamp))) || ''],
        [translate("SpO2Min"), session?.parts?.analyzing?.values.SpO2Minimum + " %" || ''],
        [translate("SpO2Max"), session?.parts?.analyzing?.values.SpO2Maximum + " %" || ''],
        [translate("SpO2Average"), session?.parts?.analyzing?.values.SpO2Average + " %" || ''],
        [translate("SpO2Std"), session?.parts?.analyzing?.values.SpO2StandardDeviation || ''],
        [translate("PulseMin"), session?.parts?.analyzing?.values.PulseMinimum + " " + translate("bpm") || ''],
        [translate("PulseMax"), session?.parts?.analyzing?.values.PulseMaximum + " " + translate("bpm") || ''],
        [translate("PulseAverage"), session?.parts?.analyzing?.values.PulseAverage + " " + translate("bpm") || ''],
        [translate("PulseStd"), session?.parts?.analyzing?.values.PulseStandardDeviation || ''],
        [translate("O2FlowMin"), session?.parts?.analyzing?.values.OxygenMinimum + " " + translate("l/min") || ''],
        [translate("O2FlowMax"), session?.parts?.analyzing?.values.OxygenMaximum + " " + translate("l/min") || ''],
        [translate("O2FlowAverage"), session?.parts?.analyzing?.values.OxygenAverage + " " + translate("l/min") || ''],
        [translate("O2FlowStd"), session?.parts?.analyzing?.values.OxygenStandardDeviation || ''],
        
        [],
        [translate("Adjusting")],
        [translate("Timestamp"), TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(session?.parts?.adjusting?.timestamp))) || ''],
        [translate("SpO2Min"), session?.parts?.adjusting?.values.SpO2Minimum + " %" || ''],
        [translate("SpO2Max"), session?.parts?.adjusting?.values.SpO2Maximum + " %" || ''],
        [translate("SpO2Average"), session?.parts?.adjusting?.values.SpO2Average + " %" || ''],
        [translate("SpO2Std"), session?.parts?.adjusting?.values.SpO2StandardDeviation || ''],
        [translate("PulseMin"), session?.parts?.adjusting?.values.PulseMinimum + " " + translate("bpm") || ''],
        [translate("PulseMax"), session?.parts?.adjusting?.values.PulseMaximum + " " + translate("bpm") || ''],
        [translate("PulseAverage"), session?.parts?.adjusting?.values.PulseAverage + " " + translate("bpm") || ''],
        [translate("PulseStd"), session?.parts?.adjusting?.values.PulseStandardDeviation || ''],
        [translate("O2FlowMin"), session?.parts?.adjusting?.values.OxygenMinimum + " " + translate("l/min") || ''],
        [translate("O2FlowMax"), session?.parts?.adjusting?.values.OxygenMaximum + " " + translate("l/min") || ''],
        [translate("O2FlowAverage"), session?.parts?.adjusting?.values.OxygenAverage + " " + translate("l/min") || ''],
        [translate("O2FlowStd"), session?.parts?.adjusting?.values.OxygenStandardDeviation || ''],
        
        [],
        [translate("End")],
        [translate("Timestamp"), TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(new Date(session?.parts?.end?.timestamp))) || ''],
        [translate("NextSession"), TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(TimeUtils.addSeconds(new Date(session?.parts?.end?.timestamp),parseInt(session?.parts?.end?.values?.NextDiagnosisSeconds)))) || ''],
        // [translate("NextIntervention"), session?.parts?.end?.values.NextDiagnosis || ''],
        [translate("LastMeasuredSpO2"), session?.parts?.end?.values.SpO2Final + " %" || ''],
        [translate("LastMeasuredPulse"), session?.parts?.end?.values.PulseFinal + " " + translate("bpm") || ''],
        [translate("OxygenTarget"), session?.parts?.end?.values.OxygenTarget + " " + translate("l/min") || ''],
        [translate("Zone"), session?.parts?.end?.values.Zone || ''],
        [],
        [translate("DetailedData")],
        [translate("UTCTimestamp"), translate("LocalTime"), translate("SpO2"), translate("O2Flow"), translate("Pulse")]
    ];
    
    

    detailedSessions.forEach((batch) => {
        var values = batch.values;

        values.forEach((value) => {
            rows.push([value.timestamp, TimeUtils.utcToLocal(new Date(value.timestamp),true), value.spO2, value.o2, value.pulse])

        })


    });


    let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "O2matic - " + translate("Patient") + " " + detailedSessions[0].patientId + " - " + translate("Session") + " " + session?.deviceSessionId + ".csv");
    document.body.appendChild(link);
    link.click();
    (async () => {
        await LogService.saveLog([String(session.id)], "Session", "csv", detailedSessions[0].patientId).catch((error) => {
            console.error("Error saving log: ", error);
        })
    })();
}


export const SessionDialog = (props) => {



    const DetailDialogContainerRef = useRef(null);
    const [width, setWidth] = useState(300);
    const [chartLoading, setChartLoading] = useState(true);
    const [xaxisRange, setXaxisrange] = useState("all");
    const [chartData, setChartData] = useState([]);

    const handleEntered = () => {
        if (DetailDialogContainerRef.current) {
            setWidth(DetailDialogContainerRef.current.clientWidth);
        }
    };
    useEffect(() => {
        setChartData([]);
        setChartLoading(true)
    }, [props.open])
    useEffect(() => {
        (async () => {
            setChartLoading(true)
            const data = await generateChartData(props?.detailedSessions)
            setChartData(data)
            setChartLoading(false)
        })();
    }, [props.detailedSessions])



    if (!props.open) {
        return null;
    }
    const session = props.session;
    const sessionDevice = props.sessionDevice;
    const deviceType = ["Null","PRO","HOT"]

    return (

        <Dialog
            fullWidth
            maxWidth="helpBoxMd"
            sx={{ width: "100%", p: 0 }}
            open={props.open}
            onClose={props.onClose}
            TransitionProps={{ onEntered: handleEntered }}
        >
            <Box sx={{ p: 2 }}>



                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography variant="h5">{translate("Session") + ": "} {session.deviceSessionId} </Typography>
                    <Typography variant="h5">{TimeUtils.utcToLocal(session.parts.start.timestamp)}</Typography>
                </Box>
                <Box sx={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between"}}>
                    {/* <Typography variant="h5">{translate("DeviceSn") + ": "} {sessionDevice}</Typography> */}
                    {
                        chartLoading ? null :
                            props?.detailedSessions?.length == 0 ? null :
                            <Button sx={{ alignSelf: "flex-end" }} startIcon={<DownloadIcon/>} variant="contained" onClick={() => downloadCsv(session,props.detailedSessions,sessionDevice)} >{translate("DownloadCsv")} </Button>
                    }
                </Box>
                {/* <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
                    {
                        chartLoading ? null :
                            props?.detailedSessions?.length == 0 ? null :
                            <Button sx={{ alignSelf: "flex-end" }} variant="contained" onClick={() => downloadCsv(session,props.detailedSessions,sessionDevice)} >{translate("DownloadCsv")} </Button>
                    }
                    
                </Box> */}

                {/* <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Typography>{translate("DetailedSessionDescription")}</Typography>
                </Box> */}

                <Box ref={DetailDialogContainerRef} sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
                    {

                        chartLoading ? "Loading..." :
                            props?.detailedSessions?.length == 0 ? "No chart data" :
                                <Chart width={width-1} data={chartData} xaxisRange={xaxisRange}/>

                    }




                </Box>

                <Box sx={{display: "flex"}}>
                <Box sx={{width:"50%"}}>

                <Box sx={{ mt: 2 }}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h6"><Translate value="InitialValues" />:</Typography>
                    <PatientDetailText label={<Translate value="InitialSpO2" />} value={session?.parts?.start?.values.SpO2Initial + " %"}></PatientDetailText>
                    <PatientDetailText label={<Translate value="InitialPulse" />} value={session?.parts?.start?.values.PulseInitial + " " + translate("bpm")}></PatientDetailText>
                    <PatientDetailText label={<Translate value="InitialO2Flow" />} value={session?.parts?.start?.values.OxygenInitial + " " + translate("l/min")}></PatientDetailText>

                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h6"><Translate value="AnalysingPhase" /></Typography>
                    <PatientDetailText label={<Translate value="SpO2Range" />} value={session?.parts?.analyzing?.values.SpO2Minimum + " - " + session?.parts?.analyzing?.values.SpO2Maximum + " %"}></PatientDetailText>
                    <PatientDetailText label={<Translate value="SpO2Average" />} value={session?.parts?.analyzing?.values.SpO2Average + " %"}></PatientDetailText>
                    <PatientDetailText label={<Translate value="SpO2Std" />} value={session?.parts?.analyzing?.values.SpO2StandardDeviation + " %"}></PatientDetailText>

                    <br></br>
                    <PatientDetailText label={<Translate value="PulseRange" />} value={session?.parts?.analyzing?.values.PulseMinimum + " - " + session?.parts?.analyzing?.values.PulseMaximum + " " + translate("bpm")}></PatientDetailText>
                    <PatientDetailText label={<Translate value="PulseAverage" />} value={session?.parts?.analyzing?.values.PulseAverage + " " + translate("bpm")}></PatientDetailText>

                    <br></br>
                    <PatientDetailText label={<Translate value="O2FlowRange" />} value={session?.parts?.analyzing?.values.OxygenMinimum + " - " + session?.parts?.analyzing?.values.OxygenMaximum + " " + translate("l/min")}></PatientDetailText>
                    <PatientDetailText label={<Translate value="O2FlowAverage" />} value={session?.parts?.analyzing?.values.OxygenAverage + " " + translate("l/min")}></PatientDetailText>
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h6"><Translate value="AdjustingPhase" /></Typography>
                    <PatientDetailText label={<Translate value="SpO2Range" />} value={session?.parts?.adjusting?.values.SpO2Minimum + " - " + session?.parts?.adjusting?.values.SpO2Maximum + " %"}></PatientDetailText>
                    <PatientDetailText label={<Translate value="SpO2Average" />} value={session?.parts?.adjusting?.values.SpO2Average + " %"}></PatientDetailText>
                    <PatientDetailText label={<Translate value="SpO2Std" />} value={session?.parts?.adjusting?.values.SpO2StandardDeviation + " %"}></PatientDetailText>

                    <br></br>
                    <PatientDetailText label={<Translate value="PulseRange" />} value={session?.parts?.adjusting?.values.PulseMinimum + " - " + session?.parts?.adjusting?.values.PulseMaximum + " " + translate("bpm")}></PatientDetailText>
                    <PatientDetailText label={<Translate value="PulseAverage" />} value={session?.parts?.adjusting?.values.PulseAverage + " " + translate("bpm")}></PatientDetailText>

                    <br></br>
                    <PatientDetailText label={<Translate value="O2FlowRange" />} value={session?.parts?.adjusting?.values.OxygenMinimum + " - " + session?.parts?.adjusting?.values.OxygenMaximum + " " + translate("l/min")}></PatientDetailText>
                    <PatientDetailText label={<Translate value="O2FlowAverage" />} value={session?.parts?.adjusting?.values.OxygenAverage + " " + translate("l/min")}></PatientDetailText>
                </Box>

                <Box sx={{ mt: 2 }}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h6"><Translate value="UntilNextSession" />:</Typography>
                    <PatientDetailText label={<Translate value="NextSession" />} value={TimeUtils.formatDate(TimeUtils.convertUTCDateToLocalDate(TimeUtils.addSeconds(new Date(session?.parts?.end?.timestamp),parseInt(session?.parts?.end?.values.NextDiagnosisSeconds))))}></PatientDetailText>
                    {/* <PatientDetailText label={<Translate value="NextIntervention" />} value={TimeUtils.utcToLocal(session?.parts?.end?.values.NextDiagnosis)}></PatientDetailText> */}
                    <PatientDetailText label={<Translate value="LastMeasuredSpO2" />} value={session?.parts?.end?.values.SpO2Final + " %"}></PatientDetailText>
                    <PatientDetailText label={<Translate value="LastMeasuredPulse" />} value={session?.parts?.end?.values.PulseFinal + " " + translate("bpm")}></PatientDetailText>
                    <PatientDetailText label={<Translate value="O2FlowTarget" />} value={session?.parts?.end?.values.OxygenTarget + " " + translate("l/min")}></PatientDetailText>
                </Box>
                </Box>


                <Box sx={{width:"50%"}}>
                    
                    <Box sx={{ mt: 2 }}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="h6"><Translate value="SessionInformation" /></Typography>
                            <PatientDetailText label={<Translate value="LengthOfSession" />} value={TimeUtils.dateDiffToHHMMSS(new Date(session?.parts?.end?.timestamp),new Date(session?.parts?.start?.timestamp))}></PatientDetailText>
                            
                            <br></br>
                            <PatientDetailText label={<Translate value="DeviceSn" />} value={<Link href={"device/" + deviceType[sessionDevice[1]] + "/" +sessionDevice} color="inherit" underline="hover">{sessionDevice} </Link>}></PatientDetailText>
                            
                            <br></br>
                            <PatientDetailText label={<Translate value="TreatmentId" />} value={session.treatment.id}></PatientDetailText>
                            <PatientDetailText label={<Translate value="TreatmentName" />} value={session.treatment.name}></PatientDetailText>
                            
                            

                        <br></br>
                        

                        
                    </Box>

                </Box>

                </Box>


            </Box>
        </Dialog>
    )
}
