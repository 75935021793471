import React, { useState, useEffect } from 'react'
import PageContainer from '../../components/PageContainer';
import { PageHeader } from '../../components/Layout/PageHeader';
import { useNavigate } from 'react-router-dom';
import { Translate, Localize } from 'react-i18nify';
import { Box } from '@mui/system';
import { TextField, InputLabel, Select, MenuItem, Button, Checkbox, FormControl, FormLabel, FormGroup, FormControlLabel, Typography} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import SaveIcon from '@mui/icons-material/Save';
import { LocationService, UserService, RoleService} from '../../services/DataService';
import { translate } from 'react-i18nify';
import AlertBox from '../../components/AlertBox';

export default function UserCreate() {

    let navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [location, setLocation] = useState(-1);
    const [loadingLocation, setloadingLocation] = useState(false);
    const [locations, setLocations] = useState([]);
    const [locationSelected, setLocationSelected] = useState(false);
    const [roles, setRoles] = useState([])
    const [rolesToAdd, setRolesToAdd] = useState([])
    const [rolesProduction, setRolesProduction] = useState([])
    const [rolesO2matic, setRolesO2matic] = useState([])

    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );

    const [open, setOpen] = useState(false);
    const toggleHelp = () => {
        setOpen(old => !old);
    };

    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }

    const dismissAlertBox=()=>{
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }

	const helpIconTheme = {
		//mr: {md:3, xs: 28},
		color: open ? "primary.dark" : "neutral.800", 
		width:"18px",
	}

    useEffect(() => {
        (async () => {
            // locations
            let apiResult = await LocationService.getAll();
            let locations = apiResult.data.sort((a, b) => a.name > b.name ? 1 : -1);
            locations.unshift({
                "dataRetentionDays": 180,
                "id": -1,
                "name": translate("SelectLocation"),
            })
            setLocation(-1);
            setLocations(locations);
            setloadingLocation(true);

        })();
    }, []);

    const handleLocationChange = (event, newValue) => {
        setLocationSelected(false); // resets location and checkboxes
        setLocation(newValue.props.value);
        // rolesToAdd = [];
    };

    useEffect(() => {
        {location==-1?setLocationSelected(false):setLocationSelected(true)}
        (async () => {
            // roles
            let getRoles = await RoleService.getByRoleCode("HUMAN").then((response) => {return response});
            let getRolesProduction = await RoleService.getByRoleCode("PRODUCTION").then((response) => {return response});
            let getRolesO2matic = await RoleService.getByRoleCode("O2MATIC").then((response) => {return response});
            let rolesFromResponse = [];
            let productionRoles = [];
            let o2maticRoles = [];
            if (location !== 21) { // if location is not O2matic
                if (location == 22) { // if location is alpha -> then remove "CtManager" role
                    getRoles.data.forEach(element => {
                        if (element.id !== 11) {
                            rolesFromResponse.push({ id: element.id, name: element.name, roleTypeId: element.roleTypeId, roleRestrictionId: element.roleRestrictionId})
                        }
                    });
                    // add production roles to Alpha location
                    getRolesProduction.data.forEach(element => {
                        rolesFromResponse.push({ id: element.id, name: element.name, roleTypeId: element.roleTypeId, roleRestrictionId: element.roleRestrictionId})
                    });
                } else { // if location is not O2matic or alpha -> then remove "CtManager" and "Repairer" roles
                    getRoles.data.forEach(element => {
                        if (element.id == 11 || element.id == 20) {
                            
                        } else {
                            rolesFromResponse.push({ id: element.id, name: element.name, roleTypeId: element.roleTypeId, roleRestrictionId: element.roleRestrictionId})
                        }
                    });
                }
            } else { // if location is o2matic -> add all roles
                getRoles.data.forEach(element => {
                rolesFromResponse.push({ id: element.id, name: element.name, roleTypeId: element.roleTypeId, roleRestrictionId: element.roleRestrictionId})
                });

                // add O2maticQA role to O2matic location
                getRolesO2matic.data.forEach(element => {
                    if (element.id !== 19) {
                        rolesFromResponse.push({ id: element.id, name: element.name, roleTypeId: element.roleTypeId, roleRestrictionId: element.roleRestrictionId})
                    }
                });

                // add production roles to O2matic location
                getRolesProduction.data.forEach(element => {
                    rolesFromResponse.push({ id: element.id, name: element.name, roleTypeId: element.roleTypeId, roleRestrictionId: element.roleRestrictionId})
                });
            }
            
            getRolesProduction.data.forEach(element => {
                productionRoles.push({ value: element.id, label: element.name })
            });
            getRolesO2matic.data.forEach(element => {
                o2maticRoles.push({ value: element.id, label: element.name })
            });
            setRoles(rolesFromResponse);
            setRolesProduction(productionRoles);
            setRolesO2matic(o2maticRoles);
            console.log(o2maticRoles);
        })();
    }, [location]);

    // let rolesToAdd = [];
    const handleChange = (event) => {
        let roleClicked = event.target.name;
        let roleIdClicked = event.target.value;
        let roleStatus = event.target.checked; // true or false
        if (roleStatus) { // if status checked = true, then add to list
            let roleToAdd = roles.find((role) => {
                return role.id === parseInt(roleIdClicked)
            })

            setRolesToAdd(oldRolestoAdd => [...oldRolestoAdd, roleToAdd])
        } else { // if status checked = false, then remove from list
            setRolesToAdd(roles => roles.filter((role) => role.roleId !== roleIdClicked))
            // remove role from list
        }
        
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        // create user
        UserService.create(firstName,lastName,email,location,rolesToAdd).then(result => {
            handleAlertBox(true, result.status, result.statusText, null) 
            setTimeout(()=>{
                navigate("/user/" + result.data.id, { replace: true });
            },1000)
        }).catch(err => {
            handleAlertBox(true, err?.response?.status, null, err?.response?.data?.join(",")) 
        })
    }
    const renderCreateUserForm = (location) => {
        return (
            <Box sx={{display:"flex", flexDirection:"row",  alignItems: 'flex-start', mt:2 }}>
                <Box onSubmit={handleSubmit} component="form" sx={{ width: { xs: "95%", sm: "400px" }, display: "flex", flexDirection: "column" }}>

                    <InputLabel id="nameLabel"><Translate value="Name" /> *</InputLabel>
                    <TextField sx={{ width: "100%" }}
                        id="firstName"
                        required
                        onChange={e=>setFirstName(e.target.value)}
                        value={firstName}>
                    </TextField>

                    <InputLabel id="lastNameLabel"><Translate value="LastName" /> *</InputLabel>
                    <TextField sx={{ width: "100%" }}
                        id="lastName"
                        required
                        onChange={e=>setLastName(e.target.value)}
                        value={lastName}>
                    </TextField>

                    <InputLabel id="emailLabel"><Translate value="Email" /> *</InputLabel>
                    <TextField sx={{ width: "100%" }}
                        id="email"
                        type="email"
                        required
                        onChange={e=>setEmail(e.target.value)}
                        value={email}>
                    </TextField>

                    <InputLabel id="locationLabel"><Translate value="Location" /> *</InputLabel>
                            <Select
                                required
                                id="location"
                                value={location || "Select location"}
                                onChange={handleLocationChange}
                                sx={{mb:2}}
                            >
                                {locations.map(location => {
                                    return <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
                                })}
                            </Select>
                            

                    {               
                    locationSelected?
                    <Box sx={{display:"flex", flexDirection:"row", width:"100%", justifyContent: 'space-between', mb:2 }}>
                    <FormControl component="fieldset">
                    <FormLabel component="legend"><Translate value="Roles"/></FormLabel>
                    <FormGroup aria-label="position">
                        {roles.map((role) => {
                            return (
                                <FormControlLabel
                                    key={role.id}
                                    value={role.id}
                                    control={<Checkbox onChange={handleChange} name={role.name} />}
                                    label={translate(role.name)}
                                />
                            )
                        })}
                    </FormGroup>
                    </FormControl>
                    <HelpIcon onClick={toggleHelp} sx={helpIconTheme} />
                    </Box>
                    :null
                    }
                    

                    <Button type="submit" variant='contained' startIcon={<SaveIcon />} color="success" sx={{ width: "200px", mr: 2}}>
                        <Translate value="Save"/>
                    </Button>
                </Box>
                <Box sx={{width: "350px", marginLeft: "30px", display: open ? "flex" : "none"}}  >
                    {(roles.length>0)?
                        <Box sx={{ p: 2, backgroundColor: "primary.light",  borderRadius: "15px", display:"flex", flexDirection:"column"}}>
                            {roles.map((role) => {
                                return(
                                <Box key={role.id}>
                                <Typography variant="h6"><Translate value={role.name} /></Typography>
                                <Translate value={role.name +"Description"} />
                                </Box>
                                )
                            })}

                        </Box>
                    : null}
                </Box> 
            </Box>

        )

    }
    return (
        <PageContainer>
                <PageHeader leftAlign><Translate value="AddUser" /></PageHeader>
               {renderCreateUserForm(location)}
               <AlertBox visible={alertBox.visiblity} text={alertBox.text} error={alertBox.error} statusCode={alertBox.statusCode} onHide={dismissAlertBox}/>
        </PageContainer >
    )
}
